import React, { useCallback } from "react"
import { Grid, Divider, Button, LinearProgress } from "@mui/material"
import csvIcon from "../../assets/images/CsvIcon.svg"
import CheckIcon from "@mui/icons-material/Check"
import DeleteIcon from "@mui/icons-material/Delete"
import ClearIcon from "@mui/icons-material/Clear"

import { getTranslations } from "src/utils/helper"
import { Translates } from "src/i18n/i18n"
import { t } from "i18next"
import BulkUploadAlert from "./BulkUploadAlert"
import BulkUploadData from "./BulkUploadData"

const BulkUploadText = ({
  showError,
  saveDisable,
  progress,
  files,
  showPopup,
  bulkLocationFilePath,
  getRootProps,
  handleCsvFile,
  onDeleteClick,
  onClearClick,
  onDeleteFileClick,
  handleCancelClick,
  handleDataMapping,
  alertError,
  typeOfFile,
  url
}) => {
  const handleOnDeleteClick = useCallback((e: any) => {
    onDeleteClick(e)
  }, [])

  const handleOnClearClickClick = useCallback((e: any) => {
    onClearClick(e)
  }, [])

  const handleOnDeleteFileClick = useCallback((e: any) => {
    onDeleteFileClick(e)
  }, [])
  return (
    <div data-testid="BulkFileUploadParentDiv">
      <Grid container>
        <Grid item xs={12}>
          <h4 className="inner-view__title">
            {getTranslations(t, Translates.Choose_File)}
          </h4>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={12} sm={6}>
            <BulkUploadData fileUploadType={typeOfFile} />
            <Divider className="dashed mb-4" />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Grid container className="justify-content-end">
              <Grid item xs={12} sm={8}>
                <BulkUploadAlert templateUrl={url} downloadType={typeOfFile} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <h6>{getTranslations(t, Translates.Upload_CSV_File)}</h6>
        <div>
          <div
            {...getRootProps({ className: "dropzone" })}
            className="upload-file mt-1" data-testid="dropzone"
          >
            {bulkLocationFilePath !== "" ? (
              <> {handleCsvFile()}</>
            ) : (
              <>
                <img
                  src={csvIcon}
                  className="upload-file__icon mt-5"
                  style={{ cursor: "pointer" }}
                  alt="csv-icon"
                />
                <p>
                  <label htmlFor="photo">
                    {getTranslations(t, Translates.Drop_your_CSV_file_here_or)}
                    <Button
                      component="span"
                      variant="text"
                      className="primary-btn f-600 ml-5p"
                    >
                      {getTranslations(t, Translates.browse)}
                    </Button>
                  </label>
                </p>
              </>
            )}

            {bulkLocationFilePath !== "" ? (
              <div className="upload-file__actions">
                {showPopup === false ? (
                  <DeleteIcon
                    data-testid="delete-icon"
                    onClick={e => handleOnDeleteClick(e)}
                  />
                ) : (
                  <div
                    className="upload-file__actions--row"
                    data-testid="popup"
                  >
                    <span>{getTranslations(t, Translates.Are_you_sure)}</span>
                    <ClearIcon
                      className="mr-2"
                      data-testid="clear-icon"
                      onClick={e => handleOnClearClickClick(e)}
                    />
                    <CheckIcon
                      data-testid="delete-Fileicon"
                      onClick={e => handleOnDeleteFileClick(e)}
                    />
                  </div>
                )}
              </div>
            ) : null}
          </div>
        </div>
        {files.length ? (
          <Grid container spacing={2} sm={6}>
            <Grid item xs={8}>
              <div className={`custom-progressbar mt-1`}>
                <LinearProgress
                  color="success"
                  value={progress}
                  className={`custom-progressbar__bar}`}
                  variant="determinate"
                />
              </div>
            </Grid>
            <Grid item xs={4}>
              <span className="font-15">{progress}%</span>
            </Grid>
          </Grid>
        ) : null}
      </Grid>
      <Grid item xs={12} sm={6}>
        <Grid container className="justify-content-start mt-4">
          <Grid item xs={12}>
            {alertError?.title !== "" ? <>{showError()}</> : null}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <div className="inner-view__footer">
          <Button
            className="primary-btn mr-5 cancel-btn"
            variant="text"
            data-testid="CancelBtn"
            onClick={handleCancelClick}
          >
            {getTranslations(t, Translates.Cancel)}
          </Button>
          <Button
            variant="contained"
            disabled={saveDisable()}
            className="primary-btn"
            data-testid="handleDataMappingBtn"
            onClick={handleDataMapping}
          >
            {getTranslations(t, Translates.Next_Data_Mapping)}
          </Button>
        </div>
      </Grid>
    </div>
  )
}
export default React.memo(BulkUploadText)
