import { Grid, Alert, AlertTitle, Snackbar, Typography } from "@mui/material"
import csvIcon from "../../assets/images/CsvIcon.svg"
import WarningIcon from "@mui/icons-material/Warning"
import React, { useState, useEffect } from "react"
import MiniHeader from "src/components/MiniHeader/MiniHeader"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import { PATH } from "src/constants/paths"
import { useSelector } from "react-redux"
import { RootState, useAppThunkDispatch } from "src/redux/store"
import { useDropzone } from "react-dropzone"
import { setBulkLocationFile } from "src/redux/slices/settingsSlice"
import { Translates } from "src/i18n/i18n"
import { read, utils } from "xlsx"
import {
  createURLFromByteArray,
  fileExtensionCheck,
  getTranslations,
  processCSV
} from "src/utils/helper"
import BulkFIleUpload from "../Component/BulkFIleUpload"
import { BULK_UPLOADING } from "src/utils/constants"
const CreateLocationBulk = ({ setPage }) => {
  const { t } = useTranslation<any>()
  const history = useHistory()
  const [progress, setProgress] = useState(0)
  const dispatch = useAppThunkDispatch()
  const [showPopup, setShowPopup] = useState(false)
  const customerId = useSelector(
    (state: RootState) => state.settings?.selectedCustomerForOperation
  )
  const bulkLocationFilePath = useSelector(
    (state: RootState) => state.settings?.bulkLocationFilePath
  )
  const locationCsvData = useSelector(
    (state: RootState) => state.settings.locationFileData
  )
  const template = useSelector(
    (state: RootState) => state.auth?.templates.LOCATION
  )
  const [alertError, setAlertError] = React.useState({ title: "", content: "" })
  const [files, setFiles] = useState([] as any)
  const [snackBar, setSnackBar] = useState({
    open: false,
    message: "",
    autoHideDuration: 3000
  })
  // istanbul ignore next
  const onSnackClose = () => {
    setSnackBar({ ...snackBar, open: false })
  }
  useEffect(() => {
    if (Array.isArray(locationCsvData) && locationCsvData.length === 0) {
      setAlertError({
        title: getTranslations(t, Translates.No_data_found),
        content: getTranslations(
          t,
          Translates.Please_ensure_the_file_you_are_uploading_contains_data
        )
      })
    }
  }, [locationCsvData])
  const { getRootProps } = useDropzone({
    multiple: false,
    accept: {
      "text/csv": [".csv"]
    },
    onError: error => {},
    onDrop: (acceptedFiles, rejectedFiles) => {
      // istanbul ignore next
      if (rejectedFiles.length) {
        setAlertError(oldVal => ({
          ...oldVal,
          severity: "error",
          title: getTranslations(t, Translates.Error),
          content: getTranslations(t, Translates.File_type_must_be_text)
        }))
      }
      // istanbul ignore next
      else if (acceptedFiles.length) {
        const fileName: string = acceptedFiles[0].name

        let errorFind = 0
        if (!fileExtensionCheck({ fileName, setAlertError, t })) {
          errorFind++
        }

        if (errorFind <= 0) {
          setAlertError({ title: "", content: "" })
          setFiles(acceptedFiles)
          dispatch(setBulkLocationFile(fileName))
          const reader = new FileReader()
          reader.addEventListener("progress", event => {
            if (event.loaded && event.total) {
              const percent = (event.loaded / event.total) * 100
              setProgress(percent)
            }
          })
          reader.onload = async ({ target }) => {
            // istanbul ignore next

            if (target?.result) {
              const workbook = read(target.result, { type: "binary" })
              const worksheet = workbook.Sheets[workbook.SheetNames[0]]
              const csvData = utils.sheet_to_csv(worksheet, { FS: "," })
              processCSV(
                csvData,
                dispatch,
                BULK_UPLOADING.LOCATION_FILE_UPLOADING
              )
            }
          }
          reader.readAsBinaryString(acceptedFiles[0])
        }
      }
    }
  })
  const handleCsvFile = () => {
    return (
      <div>
        <img
          src={csvIcon}
          className="upload-file__icon mt-5"
          style={{ cursor: "pointer" }}
        />
        <Typography variant="body2">
          <strong>{files[0]?.path || bulkLocationFilePath}</strong>{" "}
          {getTranslations(t, Translates.file_uploaded)}
        </Typography>
      </div>
    )
  }
  const handleDataMapping = () => {
    setPage("page2")
  }
  const handleNoCustomerId = () => {
    if (!customerId) {
      setTimeout(() => {
        history.push(PATH.LOCATIONS)
      }, 3000)
    }
  }
  const onDeleteClick = e => {
    e.stopPropagation()
    setShowPopup(true)
  }
  const onClearClick = e => {
    e.stopPropagation()
    setShowPopup(false)
  }
  const onDeleteFileClick = e => {
    e.stopPropagation()
    setShowPopup(false)
    setFiles([])
    setAlertError({ title: "", content: "" })
    dispatch(setBulkLocationFile(""))
  }
  const handleCancelClick = () => {
    history.push(PATH.LOCATION_TYPE_SELECT)
  }
  const saveDisable = () => {
    if (bulkLocationFilePath && alertError?.title === "") {
      return false
    } else {
      return true
    }
  }
  const showError = () => {
    return (
      <Alert
        severity="error"
        icon={<WarningIcon />}
        data-testid="error-alert"
        className="custom-alert grey"
      >
        <AlertTitle className="custom-alert__title" data-testid="alert-title">
          {alertError?.title}
        </AlertTitle>
        <p data-testid="alert-content">{alertError?.content}</p>
      </Alert>
    )
  }
  return (
    <>
      <Grid container>
        <MiniHeader
          headerTitle={getTranslations(t, Translates.UPLOAD_LOCATIONS)}
          multiCustomer={handleNoCustomerId}
        />
        <Grid container className="inner-view">
          <Grid item xs={12} className="main-container">
            <BulkFIleUpload
              getRootProps={getRootProps}
              bulkLocationFilePath={bulkLocationFilePath}
              handleCsvFile={handleCsvFile}
              onDeleteClick={onDeleteClick}
              onClearClick={onClearClick}
              onDeleteFileClick={onDeleteFileClick}
              progress={progress}
              files={files}
              showPopup={showPopup}
              handleCancelClick={handleCancelClick}
              handleDataMapping={handleDataMapping}
              saveDisable={saveDisable}
              alertError={alertError}
              url={createURLFromByteArray(template)}
              showError={showError}
              typeOfFile={BULK_UPLOADING.LOCATION_FILE_UPLOADING}
            />
          </Grid>
        </Grid>
      </Grid>
      <Snackbar
        {...snackBar}
        onClose={onSnackClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      />
    </>
  )
}
export default React.memo(CreateLocationBulk)
