import { Button, Grid, Tooltip } from "@mui/material"
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined"
import AddIcon from "@mui/icons-material/Add"
import React, { useState } from "react"
import BreadCrumb from "../BreadCrumb/BreadCrumb"
import { useHistory } from "react-router-dom"
import { PATH } from "../../../src/constants/paths"
import { useSelector } from "react-redux"
import { RootState, useAppThunkDispatch } from "../../../src/redux/store"
import SelectCustomerDropdown from "../SelectCustomerDropdown/SelectCustomerDropdown"
import { setScheduleFilters } from "../../../src/redux/slices/scheduleSlice"
import PermissionsAccessGate from "../UserAccessMngt/PermissionAccessGate"
import { useTranslation } from "react-i18next"
import { Translates } from "src/i18n/i18n"
import { getTranslations } from "src/utils/helper"
import { FEATURES, PERMISSIONS } from "src/utils/constants"

const ScheduleToolBar: React.FC<any> = ({
  handleFilter,
  filterCount,
  futureEventsDataCheck,
  schedule
}) => {
  const customerList = useSelector(
    (state: RootState) => state.settings.customers
  )
  const { t } = useTranslation<any>()
  const selectedCustomerList = customerList.filter((c: any) => c.selected)
  const [showSelectCustomer, setShowSelectCustomer] = useState(false)
  const schedulesPrevFilter = useSelector(
    (state: RootState) => state.schedule?.scheduleFilterForm
  )
  const dispatch = useAppThunkDispatch()
  const history = useHistory()
  const getBreadcrumbConfig = () => {
    let currentPath = ""
    let variables: any = {}
    if (!futureEventsDataCheck) {
      currentPath = `schedule`
      variables = {
        schedule: getTranslations(t, Translates.Schedule)
      }
    } else {
      currentPath = `configuration`
      variables = {
        configuration: getTranslations(t, Translates.Configuration)
      }
    }
    const bConfig: any = {
      currentPath,
      mapObj: variables
    }
    return bConfig
  }
  const handleFilterClick = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
    dispatch(setScheduleFilters(schedulesPrevFilter))
    handleFilter()
  }
  const handleAddSchedule = () => {
    if (selectedCustomerList.length > 1) {
      setShowSelectCustomer(true)
    } else {
      history.push(PATH.SCHEDULE_CREATE_TYPE)
    }
  }
  const handleContinue = () => {
    setShowSelectCustomer(false)
    history.push(PATH.SCHEDULE_CREATE_TYPE)
  }

  return (
    <>
      <div className="main-toolbar" data-testid="ScheduleTB">
        <div className="main-container">
          <Grid container>
            <Grid item sm={5}>
              <div className="custom-breadcrumb">
                <BreadCrumb
                  customPath={"Schedule"}
                  breadCrumbInfo={getBreadcrumbConfig()}
                  renderedPage={""}
                />
              </div>
            </Grid>
            {schedule && schedule.length > 0 ? (
              <Grid item sm={7}>
                <div className="toolbar-actions text-right">
                  <Tooltip arrow title={getTranslations(t, Translates.Filter)}>
                    <Button
                      variant="text"
                      className=" mx-2 icon-btn"
                      data-testid="filter-button"
                      onClick={handleFilterClick}
                    >
                      <FilterListOutlinedIcon data-testid="filter" />
                      {filterCount !== 0 ? (
                        <span className="filter-icon__count">
                          {filterCount}
                        </span>
                      ) : null}
                    </Button>
                  </Tooltip>
                  {!futureEventsDataCheck && (
                    <PermissionsAccessGate
                      feature={FEATURES.SCHEDULE}
                      permissionKey={PERMISSIONS.CREATE}
                    >
                      <Button
                        data-testid="HARBtn"
                        variant="contained"
                        className="primary-btn"
                        onClick={handleAddSchedule}
                      >
                        <AddIcon
                          data-testid="addNewSchedule"
                          className="mr-1"
                        />{" "}
                        {getTranslations(t, Translates.New_Schedule)}
                      </Button>
                    </PermissionsAccessGate>
                  )}
                </div>
              </Grid>
            ) : null}
            {showSelectCustomer ? (
              <SelectCustomerDropdown
                close={() => setShowSelectCustomer(false)}
                open={showSelectCustomer}
                onContinue={handleContinue}
              />
            ) : null}
          </Grid>
        </div>
      </div>
    </>
  )
}
export default ScheduleToolBar
