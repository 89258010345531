import { Grid, Alert, AlertTitle, Snackbar, Typography } from "@mui/material"
import csvIcon from "../../assets/images/CsvIcon.svg"
import WarningIcon from "@mui/icons-material/Warning"
import React, { useState, useEffect } from "react"
import MiniHeader from "src/components/MiniHeader/MiniHeader"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import { PATH } from "src/constants/paths"
import { useSelector } from "react-redux"
import { RootState, useAppThunkDispatch } from "src/redux/store"
import { useDropzone } from "react-dropzone"
import { Translates } from "src/i18n/i18n"
import { read, utils } from "xlsx"
import {
  createURLFromByteArray,
  fileExtensionCheck,
  getTranslations,
  processCSV
} from "src/utils/helper"
import BulkFIleUpload from "../Component/BulkFIleUpload"
import { BULK_UPLOADING } from "src/utils/constants"
import { setUploadFilePath } from "src/redux/slices/uploadFileSlice"
import { FILE_TYPE, PAGES } from "../Location/UploadFileHelper"

const UploadFileLocationBulk = ({ setPage, FileType }) => {
  const { t } = useTranslation<any>()
  const history = useHistory()
  const [progress, setProgress] = useState(0)
  const dispatch = useAppThunkDispatch()
  const [showPopup, setShowPopup] = useState(false)
  const customerId = useSelector(
    (state: RootState) => state.settings?.selectedCustomerForOperation
  )
  const bulkUploadFilePath = useSelector(
    (state: RootState) => state.uploadFiles?.uploadFileFilePath
  )

  const uploadCsvData = useSelector(
    (state: RootState) => state.uploadFiles?.uploadFilesFullData
  )
  const varianceTemplate = useSelector(
    (state: RootState) => state.auth?.templates?.VARIANCE
  )
  const deptTemplate = useSelector(
    (state: RootState) => state.auth?.templates?.DEPARTMENT
  )
  const [alertError, setAlertError] = React.useState({ title: "", content: "" })
  const [files, setFiles] = useState([] as any)
  const [snackBar, setSnackBar] = useState({
    open: false,
    message: "",
    autoHideDuration: 3000
  })
  //istanbul ignore next
  const onSnackClose = () => {
    setSnackBar({ ...snackBar, open: false })
  }
  useEffect(() => {
    dispatch(setUploadFilePath(""))
  }, [])
  useEffect(() => {
    if (Array.isArray(uploadCsvData) && uploadCsvData.length === 0) {
      setAlertError({
        title: getTranslations(t, Translates.No_data_found),
        content: getTranslations(
          t,
          Translates.Please_ensure_the_file_you_are_uploading_contains_data
        )
      })
    }
  }, [uploadCsvData])
  //istanbul ignore next
  //checking case in BulkFileUpload comp
  const { getRootProps } = useDropzone({
    multiple: false,
    accept: {
      "text/csv": [".csv"]
    },
    onError: error => {},
    onDrop: (acceptedFiles, rejectedFiles) => {
      if (rejectedFiles.length) {
        setAlertError(oldVal => ({
          ...oldVal,
          severity: "error",
          title: getTranslations(t, Translates.Error),
          content: JSON.stringify(rejectedFiles[0].errors[0].message) || ""
        }))
      } else if (acceptedFiles.length) {
        const fileName: string = acceptedFiles[0].name
        let errorFind = 0
        if (!fileExtensionCheck({ fileName, setAlertError, t })) {
          errorFind++
        }
        if (errorFind <= 0) {
          setAlertError({ title: "", content: "" })
          setFiles(acceptedFiles)
          dispatch(setUploadFilePath(fileName))
          const reader = new FileReader()
          reader.addEventListener("progress", event => {
            if (event.loaded && event.total) {
              const percent = (event.loaded / event.total) * 100
              setProgress(percent)
            }
          })
          reader.onload = async ({ target }) => {
            if (target?.result) {
              const workbook = read(target.result, { type: "binary" })
              const worksheet = workbook.Sheets[workbook.SheetNames[0]]
              const csvData = utils.sheet_to_csv(worksheet, { FS: "," })
              processCSV(csvData, dispatch, BULK_UPLOADING.UPLOADFILE)
            }
          }
          reader.readAsBinaryString(acceptedFiles[0])
        }
      }
    }
  })

  const handleCsvFile = () => {
    return (
      <div>
        <img
          src={csvIcon}
          className="upload-file__icon mt-5"
          style={{ cursor: "pointer" }}
        />
        <Typography variant="body2">
          <strong>{files[0]?.path || bulkUploadFilePath}</strong>{" "}
          {getTranslations(t, Translates.file_uploaded)}
        </Typography>
      </div>
    )
  }
  const handleDataMapping = () => {
    setPage(PAGES.page2)
  }
  const handleNoCustomerId = () => {
    if (!customerId) {
      setTimeout(() => {
        history.push(PATH.UPLOADFILES)
      }, 3000)
    }
  }
  const onDeleteClick = e => {
    e.stopPropagation()
    setShowPopup(true)
  }
  //istanbul ignore next
  //checking case in BulkFileUpload comp
  const onClearClick = e => {
    e.stopPropagation()
    setShowPopup(false)
  }
  const onDeleteFileClick = e => {
    e.stopPropagation()
    setShowPopup(false)
    setFiles([])
    setAlertError({ title: "", content: "" })
    dispatch(setUploadFilePath(""))
  }
  const handleCancelClick = () => {
    history.push(PATH.UPLOADFILES)
  }

  const saveDisable = () => {
    if (bulkUploadFilePath && alertError?.title === "") {
      return false
    } else {
      return true
    }
  }
  const urlLink = () => {
    if (FileType === FILE_TYPE.Department) {
      return createURLFromByteArray(deptTemplate)
    } else {
      return createURLFromByteArray(varianceTemplate)
    }
  }
  const showError = () => {
    return (
      <Alert
        severity="error"
        icon={<WarningIcon />}
        className="custom-alert grey"
      >
        <AlertTitle className="custom-alert__title">
          {alertError?.title}
        </AlertTitle>
        <p>{alertError?.content}</p>
      </Alert>
    )
  }
  return (
    <>
      <Grid container>
        <MiniHeader
          headerTitle={getTranslations(t, Translates.UPLOAD_FILES)}
          multiCustomer={handleNoCustomerId}
        />
        <Grid container className="inner-view">
          <Grid item xs={12} className="main-container">
            <BulkFIleUpload
              getRootProps={getRootProps}
              bulkLocationFilePath={bulkUploadFilePath}
              handleCsvFile={handleCsvFile}
              onDeleteClick={onDeleteClick}
              onClearClick={onClearClick}
              onDeleteFileClick={onDeleteFileClick}
              progress={progress}
              files={files}
              showPopup={showPopup}
              handleCancelClick={handleCancelClick}
              handleDataMapping={handleDataMapping}
              saveDisable={saveDisable}
              alertError={alertError}
              showError={showError}
              typeOfFile={
                FileType === FILE_TYPE.Department
                  ? BULK_UPLOADING.DEPARTMENT_FILE_UPLOADING
                  : BULK_UPLOADING.VARIANCE_FILE_UPLOADING
              }
              url={urlLink()}
            />
          </Grid>
        </Grid>
      </Grid>
      <Snackbar
        {...snackBar}
        onClose={onSnackClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      />
    </>
  )
}
export default React.memo(UploadFileLocationBulk)
