import React from "react"
import { Box } from "@mui/system"
import CircularProgress from "@mui/material/CircularProgress"

export default function Loading({
  isMarginTop = true,
  isJustifyContent = true
}) {
  return (
    <Box
      key={"loader"}
      sx={{
        display: "flex",
        alignItems: "center",
        ...(isJustifyContent && { justifyContent: "center" }),
        ...(isMarginTop && { marginTop: "300px" })
      }}
    >
      <CircularProgress />
    </Box>
  )
}
