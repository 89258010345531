import { useTranslation } from "react-i18next"
import { Translates } from "src/i18n/i18n"
import HistoryBreadCrumb from "src/configurationPortal/pages/Components/CBreadCrumb"
import { getConfigHistoryBreadcrumbPath } from "./helper"
import React, { useEffect, useState } from "react"
import {
  decryptUrlParams,
  downloadJSON,
  getTranslations
} from "src/utils/helper"
import { PATH } from "src/constants/paths"
import { useHistory } from "react-router-dom"
import { ConfigService } from "src/service/config.service"
import { Loader } from "src/utils/reusables/reusable"
import GlobalTable from "src/components/GlobalTable/GlobalTable"
import { Grid, Tooltip } from "@mui/material"
import DateFormat from "src/components/DateFormat"
import DownloadIcon from "../../assets/images/DownloadIcon.jpg"
import { displaySpan } from "src/configurationPortal/pages/FunctionHelper"

const ConfigurationHistory = () => {
  const { t } = useTranslation<any>()
  const history = useHistory()
  const [configHistories, setConfigHistories] = useState<any[]>([])
  const [loadingItems, setLoadingItems] = useState<any>({ isAPIFetched: false })
  const configService = new ConfigService()
  const { componentName, bConfig } = getConfigHistoryBreadcrumbPath(
    t,
    Translates
  )
  const params = new URLSearchParams(
    decryptUrlParams(window.location.search?.slice(1))
  )
  const configId = params.get("configId")
  useEffect(() => {
    let isMounted = true
    if (isMounted) {
      if (!configId) {
        history.push(`${PATH.CONFIGURATION}`)
      } else {
        getConfigHistory()
      }
    }
    return () => {
      isMounted = false
    }
  }, [])

  const getConfigHistory = async () => {
    try {
      const response = await configService.getConfigHistoryDetails(configId)
      setConfigHistories(response.data || [])
      setLoadingItems(oldVal => ({ ...oldVal, isAPIFetched: true }))
    } catch (error) {
      console.error(error)
    }
  }

  const DownloadJSONClick = (row: any) => {
    if (row.configuration) {
      downloadJSON(JSON.parse(row.configuration), "Configuration")
    }
  }

  const columnsData = React.useMemo(
    () => [
      {
        Header: getTranslations(t, Translates.Version),
        accessor: "version", // accessor is the "key" in the data
        headerClassName: "temp_class",
        disableFilters: true,
        disableSortBy: false,
        setWidth: 250,
        Cell: props => {
          return displaySpan(props, "-")
        }
      },
      {
        Header: getTranslations(t, Translates.User),
        accessor: "updatedUser", // accessor is the "key" in the data
        headerClassName: "temp_class",
        disableFilters: true,
        disableSortBy: false,
        setWidth: 220,
        Cell: props => {
          return displaySpan(props, "-")
        }
      },
      {
        Header: getTranslations(t, Translates.Timestamp),
        accessor: "updtatedDate", // accessor is the "key" in the data
        headerClassName: "temp_class",
        disableFilters: true,
        disableSortBy: false,
        setWidth: 250,
        Cell: props => {
          return <DateFormat {...props} isLocal={true} />
        }
      },
      {
        Header: getTranslations(t, Translates.Reference),
        accessor: "ticketNumber", // accessor is the "key" in the data
        headerClassName: "temp_class",
        disableFilters: true,
        disableSortBy: false,
        setWidth: 220,
        Cell: props => {
          return displaySpan(props, "-")
        }
      },
      {
        Header: getTranslations(t, Translates.Config_Name),
        accessor: "name", // accessor is the "key" in the data
        headerClassName: "temp_class",
        disableFilters: true,
        disableSortBy: false,
        setWidth: 220,
        Cell: props => {
          return displaySpan(props, "-")
        }
      },
      {
        Header: getTranslations(t, Translates.Historic_JSON),
        accessor: "configuration", // accessor is the "key" in the data
        headerClassName: "temp_class",
        disableFilters: true,
        disableSortBy: true,
        setWidth: 150,
        Cell: props => {
          return (
            <span className="action-btns">
              <Tooltip arrow title={getTranslations(t, Translates.Download)}>
                <img
                  data-testid="historyConfigIcon"
                  className="pointer ml-1"
                  src={DownloadIcon}
                  width="22"
                  onClick={() => DownloadJSONClick(props.row.original)}
                />
              </Tooltip>
            </span>
          )
        }
      }
    ],
    [configHistories, t]
  )
  return (
    <>
      <HistoryBreadCrumb
        customPath={componentName}
        bConfig={{ ...bConfig, ...{ isNotSearchParams: true } }}
      />
      {Object.values(loadingItems).some(i => !i) ? (
        <Loader />
      ) : (
        <div className="main-container">
          <Grid
            container
            className="custom-table-responsive"
            data-testid="configurationRender"
          >
            <Grid xs={12} item data-testid="ConfigHistoryGrid">
              <GlobalTable
                columns={columnsData}
                data={configHistories}
                noDataText={
                  configHistories.length !== 0
                    ? Loader()
                    : getTranslations(t, Translates.No_data_found)
                }
              />
            </Grid>
          </Grid>{" "}
        </div>
      )}
    </>
  )
}

export default ConfigurationHistory
