import { ProductsList } from "../../CountFields/Constants"
import {
  checkBarcodeType,
  checkBarcodeTypeForCheckDigit,
  checkDataType,
  CheckDigitAddType,
  CheckDigitModType,
  CheckDigitNextType,
  checkEntryMethod,
  currentFieldOptions,
  errorOptions,
  sourceForCombinedCountOptions,
  // TrueOrFalseWithNoDefault, // For phase 5, not required for now
  WeightOptions
} from "./fieldOptions"
import functionNames, {
  arrayWithRequired,
  numberWithRequired,
  ruleEditorFunctions,
  stringWithNotRequired,
  stringWithRequired
} from "./functionNames"

const functionTypes = functionNames
const SIDEBAR_ITEM = "sidebarItem"

export const productFieldOptions = Object.keys(ProductsList).map(i => {
  return { value: i, label: i }
})

export const checkDigitOrAddCheckDigitInputs = [
  {
    type: "autoComplete",
    name: "parameter1",
    label: "Barcode Type*",
    options: checkBarcodeTypeForCheckDigit,
    isNotSort: true,
    validation: {
      ...stringWithRequired,
      ...{ oneOf: checkBarcodeTypeForCheckDigit.map(i => i.value) }
    }
  },
  {
    type: "dropdown",
    name: "parameter2",
    label: "Add Type*",
    options: CheckDigitAddType,
    validation: {
      ...stringWithNotRequired,
      ...{ oneOf: CheckDigitAddType.map(i => i.value) }
    }
  },
  {
    type: "dropdown",
    name: "parameter3",
    label: "Next Type*",
    options: CheckDigitNextType,
    validation: {
      ...stringWithNotRequired,
      ...{ oneOf: CheckDigitNextType.map(i => i.value) }
    }
  },
  {
    type: "dropdown",
    name: "parameter4",
    label: "Mod Type*",
    options: CheckDigitModType,
    validation: {
      ...stringWithNotRequired,
      ...{ oneOf: CheckDigitModType.map(i => i.value) }
    }
  },
  {
    type: "autoComplete",
    name: "parameter5",
    label: "Weight*",
    options: WeightOptions,
    freeSolo: true,
    validation: { ...stringWithNotRequired }
  }
]

export const sourceField = {
  type: "autoComplete",
  name: "source",
  label: "Source",
  options: sourceForCombinedCountOptions,
  validation: {
    ...stringWithNotRequired,
    ...{
      oneOf: [
        ...[""],
        ...[...currentFieldOptions, ...sourceForCombinedCountOptions].map(
          i => i.value
        )
      ]
    }
  }
}

export const booleanError = {
  type: "dropdown",
  name: "booleanSource",
  label: "On Fail*",
  options: errorOptions,
  validation: { ...stringWithRequired }
}

export const booleanErrorMessage = {
  type: "inputText",
  label: "On Fail Message*",
  name: "booleanMessage",
  validation: { ...stringWithRequired }
}
const booleanFunctions = [
  {
    type: SIDEBAR_ITEM,
    function: functionTypes.CheckDataType,
    content: "Check Data Type",
    parameters: [],
    functionType: ruleEditorFunctions.booleanFn,
    inputType: [
      {
        type: "dropdown",
        name: "parameter1",
        label: "Data Type*",
        options: checkDataType,
        validation: { ...stringWithRequired }
      }
    ]
  },
  {
    type: SIDEBAR_ITEM,
    function: functionTypes.CheckLengths,
    content: "Check Length",
    functionType: ruleEditorFunctions.booleanFn,
    inputType: [
      {
        type: "inputText",
        label: "Check Length*",
        name: "parameter1",
        validation: { ...stringWithRequired }
      }
    ]
  },
  {
    type: SIDEBAR_ITEM,
    function: functionTypes.CheckLengthRange,
    content: "Check Length Range",
    parameters: [],
    functionType: ruleEditorFunctions.booleanFn,
    inputType: [
      {
        type: "inputText",
        label: "Min Length*",
        name: "parameter1",
        validation: { ...numberWithRequired }
      },
      {
        type: "inputText",
        label: "Max Length*",
        name: "parameter2",
        validation: {
          ...numberWithRequired,
          ...{ maxOfFirstField: {} }
        }
      }
    ]
  },
  {
    type: SIDEBAR_ITEM,
    function: functionTypes.CheckValueRange,
    content: "Check Value Range",
    parameters: [],
    functionType: ruleEditorFunctions.booleanFn,
    inputType: [
      {
        type: "inputText",
        label: "Min Value*",
        name: "parameter1",
        validation: { ...numberWithRequired }
      },
      {
        type: "inputText",
        label: "Max Value*",
        name: "parameter2",
        validation: {
          ...numberWithRequired,
          ...{ maxOfFirstField: {} }
        }
      }
    ]
  },
  {
    type: SIDEBAR_ITEM,
    function: functionTypes.CheckDecimalRange,
    content: "Check Decimal Range",
    parameters: [],
    functionType: ruleEditorFunctions.booleanFn,
    inputType: [
      {
        type: "inputText",
        label: "Min Value*",
        name: "parameter1",
        validation: { ...numberWithRequired }
      },
      {
        type: "inputText",
        label: "Max Value*",
        name: "parameter2",
        validation: {
          ...numberWithRequired,
          ...{ maxOfFirstField: {} }
        }
      }
    ]
  },
  {
    type: SIDEBAR_ITEM,
    function: functionTypes.CheckEntryMethod,
    content: "Check Entry Method",
    parameters: [],
    functionType: ruleEditorFunctions.booleanFn,
    inputType: [
      {
        type: "dropdown",
        name: "parameter1",
        label: "Entry Method*",
        options: checkEntryMethod,
        validation: {
          ...stringWithRequired,
          ...{ oneOf: checkEntryMethod.map(i => i.value) }
        }
      }
    ]
  },
  {
    type: SIDEBAR_ITEM,
    function: functionTypes.CheckBarcodeType,
    content: "Check Barcode Type",
    parameters: [],
    functionType: ruleEditorFunctions.booleanFn,
    inputType: [
      {
        type: "multiDropdown",
        name: "parameter1",
        label: "Barcode Type*",
        options: checkBarcodeType,
        validation: { ...arrayWithRequired }
      }
    ]
  },
  {
    type: SIDEBAR_ITEM,
    function: functionTypes.CheckDigit,
    content: "Check Digit",
    parameters: [],
    functionType: ruleEditorFunctions.booleanFn,
    inputType: checkDigitOrAddCheckDigitInputs
  },
  {
    type: SIDEBAR_ITEM,
    function: functionTypes.RegEx,
    content: "Regular Expression",
    parameters: [],
    functionType: ruleEditorFunctions.booleanFn,
    inputType: [
      {
        type: "inputText",
        label: "Regular Expression*",
        name: "parameter1",
        validation: { ...stringWithRequired }
      }
    ]
  },
  {
    type: SIDEBAR_ITEM,
    function: functionTypes.IsEqual,
    content: "Is Equal",
    parameters: [],
    functionType: ruleEditorFunctions.booleanFn,
    inputType: [
      {
        type: "inputText",
        label: "Enter Value*",
        name: "parameter1",
        validation: { ...stringWithRequired }
      }
    ]
  },
  {
    type: SIDEBAR_ITEM,
    function: functionTypes.IsGreaterThan,
    content: "Is Greater Than",
    parameters: [],
    functionType: ruleEditorFunctions.booleanFn,
    inputType: [
      {
        type: "inputText",
        label: "Enter Value*",
        name: "parameter1",
        validation: { ...numberWithRequired }
      }
    ]
  },
  {
    type: SIDEBAR_ITEM,
    function: functionTypes.IsGreaterThanOrEqual,
    content: "Is Greater Than Or Equal",
    parameters: [],
    functionType: ruleEditorFunctions.booleanFn,
    inputType: [
      {
        type: "inputText",
        label: "Enter Value*",
        name: "parameter1",
        validation: { ...numberWithRequired }
      }
    ]
  },
  {
    type: SIDEBAR_ITEM,
    function: functionTypes.IsLessThan,
    content: "Is Less Than",
    parameters: [],
    functionType: ruleEditorFunctions.booleanFn,
    inputType: [
      {
        type: "inputText",
        label: "Enter Value*",
        name: "parameter1",
        validation: { ...numberWithRequired }
      }
    ]
  },
  {
    type: SIDEBAR_ITEM,
    function: functionTypes.IsLessThanOrEqual,
    content: "Is Less Than Or Equal",
    parameters: [],
    functionType: ruleEditorFunctions.booleanFn,
    inputType: [
      {
        type: "inputText",
        label: "Enter Value*",
        name: "parameter1",
        validation: { ...numberWithRequired }
      }
    ]
  },
  {
    type: SIDEBAR_ITEM,
    function: functionTypes.IsIn,
    content: "Is In",
    parameters: [],
    functionType: ruleEditorFunctions.booleanFn,
    inputType: [
      {
        type: "inputText",
        label: "Enter Value*",
        name: "parameter1",
        validation: { ...stringWithRequired }
      }
    ]
  },
  {
    type: SIDEBAR_ITEM,
    function: functionTypes.StartsWith,
    content: "Starts With",
    parameters: [],
    functionType: ruleEditorFunctions.booleanFn,
    inputType: [
      {
        type: "inputText",
        label: "Enter Value*",
        name: "parameter1",
        validation: { ...stringWithRequired }
      }
    ]
  },
  {
    type: SIDEBAR_ITEM,
    function: functionTypes.EndsWith,
    content: "Ends With",
    parameters: [],
    functionType: ruleEditorFunctions.booleanFn,
    inputType: [
      {
        type: "inputText",
        label: "Enter Value*",
        name: "parameter1",
        validation: { ...stringWithRequired }
      }
    ]
  },
  {
    type: SIDEBAR_ITEM,
    function: functionTypes.IsNull,
    content: "Is Null",
    functionType: ruleEditorFunctions.booleanFn
  },
  {
    type: SIDEBAR_ITEM,
    function: functionTypes.IsNullOrEmpty,
    content: "Is Null Or Empty",
    functionType: ruleEditorFunctions.booleanFn
  },
  {
    type: SIDEBAR_ITEM,
    function: functionTypes.IsNullOrWhiteSpace,
    content: "Is Null Or White Space",
    functionType: ruleEditorFunctions.booleanFn
  },
  {
    type: SIDEBAR_ITEM,
    function: functionTypes.IsNotNull,
    content: "Is Not Null",
    functionType: ruleEditorFunctions.booleanFn
  },
  {
    type: SIDEBAR_ITEM,
    function: functionTypes.IsNotNullOrEmpty,
    content: "Is Not Null Or Empty",
    functionType: ruleEditorFunctions.booleanFn
  },
  {
    type: SIDEBAR_ITEM,
    function: functionTypes.IsNotNullOrWhiteSpace,
    content: "Is Not Null Or White Space",
    functionType: ruleEditorFunctions.booleanFn
  },
  {
    type: SIDEBAR_ITEM,
    function: functionTypes.HasConsecutiveSameDigits,
    content: "Has Consecutive Same Digits",
    functionType: ruleEditorFunctions.booleanFn
  }
  //Phase 4 function not required for now
  // {
  //   type: SIDEBAR_ITEM,
  //   function: functionTypes.HasConsecutiveSameCapture,
  //   content: "Has Consecutive Same Capture",
  //   functionType: ruleEditorFunctions.booleanFn,
  //   inputType: [
  //     {
  //       type: "dropdown",
  //       name: "parameter1",
  //       label: "Field*",
  //       options: productFieldOptions,
  //       validation: {
  //         ...stringWithRequired,
  //         ...{ oneOf: productFieldOptions.map(i => i.value) }
  //       }
  //     },
  //     {
  //       type: "inputText",
  //       label: "Number of consecutive captures*",
  //       name: "parameter2",
  //       validation: { ...numberWithRequired }
  //     }
  //   ]
  // },
  // Phase 5 function not required for now
  // {
  //   type: SIDEBAR_ITEM,
  //   function: functionTypes.RecordsInLoop,
  //   content: "Records In Loop",
  //   functionType: ruleEditorFunctions.booleanFn,
  //   inputType: [
  //     {
  //       type: "dropdown",
  //       name: "parameter1",
  //       label: "Field*",
  //       options: productFieldOptions,
  //       validation: {
  //         ...stringWithRequired,
  //         ...{ oneOf: productFieldOptions.map(i => i.value) }
  //       }
  //     },
  //     {
  //       type: "dropdown",
  //       name: "parameter2",
  //       label: "Unique Count*",
  //       options: TrueOrFalseWithNoDefault,
  //       validation: {
  //         ...stringWithRequired,
  //         ...{ oneOf: TrueOrFalseWithNoDefault.map(i => i.value) }
  //       }
  //     }
  //   ]
  // }
].sort((a, b) => {
  if (a.function > b.function) {
    return 1
  } else if (b.function > a.function) {
    return -1
  } else {
    return 0
  }
})

export default booleanFunctions
