import * as yup from "yup"
import fieldTypes from "src/constants/fieldTypes"
import { functionTypes } from "./LRConstants/index"
import {
  booleanError,
  booleanErrorMessage,
  sourceField
} from "./LRConstants/booleanFunctions"
import { DEFAULT_RADIX } from "src/utils/constants"
//istanbul ignore next
export const Schema = (inputTypes, fnName) => {
  let a = {}
  let requiredError = "This field is required"
  inputTypes?.forEach((item, inx) => {
    if (item.validation) {
      const validation = item.validation

      if (validation.type === fieldTypes.string) {
        let locYup = yup.string().nullable()
        if (validation.required) {
          locYup = locYup.required(validation.required?.label ?? requiredError)
        }
        if (validation.min) {
          locYup = locYup.min(validation.min.min, validation.min.error)
        }
        if (validation.max) {
          locYup = locYup.max(validation.max.max, validation.max.error)
        }
        if (validation.oneOf && validation.oneOf.length > 0) {
          locYup = locYup.oneOf(
            validation.oneOf,
            `This field value should be one of the options`
          )
        }
        if (
          fnName === functionTypes.CheckLengths &&
          ![
            sourceField.name,
            booleanError.name,
            booleanErrorMessage.name
          ].includes(item.name)
        ) {
          locYup = locYup.test(
            item.name,
            "Only allowed coma separate(no spaces) number values!",
            (value: string | undefined | null) => {
              let error: boolean = false
              if (value) {
                if (value.split(" ").length > 1) {
                  error = true
                } else {
                  const arrayVal = value.split(",")
                  if (Array.isArray(arrayVal)) {
                    arrayVal.forEach(element => {
                      if (
                        typeof parseInt(element, DEFAULT_RADIX) !== "number" ||
                        isNaN(parseInt(element, DEFAULT_RADIX))
                      ) {
                        error = true
                      }
                      if (!error) {
                        for (let i = 0; i < element.toString().length; i++) {
                          if (
                            typeof parseInt(element[i], DEFAULT_RADIX) !==
                              "number" ||
                            isNaN(parseInt(element[i], DEFAULT_RADIX))
                          ) {
                            error = true
                          }
                        }
                      }
                    })
                  }
                }
              }
              return !error
            }
          )
        }
        a = { ...a, ...{ [item.name]: locYup } }
      }

      if (validation.type === fieldTypes.number) {
        let locYup
        if (validation.required) {
          locYup = yup
            .number()
            .nullable()
            .required(validation.required?.label ?? requiredError)
          if (validation.min) {
            locYup = locYup.min(validation.min.min, validation.min.error)
          }
          if (validation.maxOfFirstField) {
            locYup = locYup.when(inputTypes[inx - 1].name, (val, locYup) => {
              return val && typeof val === "number"
                ? locYup.min(
                    val + 0.0001,
                    `Should be greater than to ${inputTypes[inx - 1].label}`
                  )
                : locYup
            })
          }
          locYup = locYup.typeError(
            validation.typeError ?? "This field should be number"
          )
        } else {
          locYup = yup
            .string()
            .test(
              item.name,
              validation.typeError ?? "This field should be number",
              (value: any) => {
                return value || value === 0 ? /^\d+$/.test(value) : true
              }
            )
        }

        a = { ...a, ...{ [item.name]: locYup } }
      }

      if (validation.type === fieldTypes.array) {
        let locYup = yup.array().nullable()
        if (validation.required) {
          locYup = locYup
            .required(validation.required?.label ?? requiredError)
            .min(1, validation.required?.label ?? requiredError)
        }
        locYup = locYup.typeError(
          validation.typeError ?? "This field should be array"
        )
        a = { ...a, ...{ [item.name]: locYup } }
      }
    }
  })

  return yup.object().shape(a)
}
