export const UPLOAD_KEYS = {
  departmentGroup: "departmentGroup",
  departmentName: "departmentName",
  departmentSort: "departmentSort",
  departmentParentName: "departmentParentName",
  departmentParentSort: "departmentParentSort",
  departmentParent: "departmentParent",
  varianceGroup: "varianceGroup",
  varianceGroupName: "varianceGroupName",
  sortOrder: "sortOrder",
  qtyTolerance: "qtyTolerance",
  fullStoreQtyTolerance: "fullStoreQtyTolerance",
  extTolerance: "extTolerance",
  fullStoreExtTolerance: "fullStoreExtTolerance"
}

export const UPLOADFILE_FIELD_MAP = {
  [UPLOAD_KEYS.departmentGroup]: "Department Group",
  [UPLOAD_KEYS.departmentName]: "Department Name",
  [UPLOAD_KEYS.departmentSort]: "Department Sort",
  [UPLOAD_KEYS.departmentParentName]: "Department Parent Name",
  [UPLOAD_KEYS.departmentParentSort]: "Department Parent Sort",
  [UPLOAD_KEYS.departmentParent]: "Department Parent",
  [UPLOAD_KEYS.varianceGroup]: "Variance Group",
  [UPLOAD_KEYS.varianceGroupName]: "Group Name",
  [UPLOAD_KEYS.sortOrder]: "Sort Order",
  [UPLOAD_KEYS.qtyTolerance]: "Qty Tolerance",
  [UPLOAD_KEYS.fullStoreQtyTolerance]: "Full Store Qty Tolerance",
  [UPLOAD_KEYS.extTolerance]: "Ext Tolerance",
  [UPLOAD_KEYS.fullStoreExtTolerance]: "Full Store Ext Tolerance"
}
export const UPLOADFILE_MAP = {
  department: "department",
  departmentName: "departmentName",
  departmentSort: "departmentSort",
  departmentParentName: "departmentParentName",
  departmentParentSort: "departmentParentSort",
  departmentParent: "departmentParent",
  varianceGroup: "varianceGroup",
  varianceGroupName: "varianceGroupName",
  sortOrder: "sortOrder",
  qtyTolerance: "qtyTolerance",
  fullStoreQtyTolerance: "fullStoreQtyTolerance",
  extTolerance: "extTolerance",
  fullStoreExtTolerance: "fullStoreExtTolerance"
}
export const PAGES = {
  page1: "page1",
  page2: "page2",
  page3: "page3",
  page4: "page4"
}
export const FILE_TYPE = {
  Department: "Department",
  VarianceControl: "VarianceControl"
}
