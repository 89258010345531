import React, { useCallback, useEffect, useMemo, useState } from "react"
import { useSelector } from "react-redux"
import { RootState, useAppThunkDispatch } from "../../../src/redux/store"
import {
  setScheduleFilterForm,
  setScheduleFilters
} from "../../../src/redux/slices/scheduleSlice"
import moment from "moment"
import { useTranslation } from "react-i18next"
import { Translates } from "src/i18n/i18n"
import FilterComponent from "src/utils/reusables/FilterComponent"
import {
  dateChangeHandler,
  filterAndSort,
  getEventStatusList,
  getRegionData,
  handleChangeCountryVal,
  handleChangeRegion,
  handleChangeSiteIdVal,
  handleChangeStateVal,
  handleChangeStatusVal,
  handleResetAll,
  sortData
} from "src/utils/helper"

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP
    },
    anchororigin: {
      vertical: "bottom",
      horizontal: "left"
    },
    transformorigin: {
      vertical: "top",
      horizontal: "left"
    },
    getcontentanchorel: null
  }
}

interface SReservation {
  scheduledDateTime: string
}

const ScheduleFilter = ({
  storeData,
  userData,
  cancelClick,
  applyClick,
  countries,
  states,
  status,
  showFilterPage,
  currentDate,
  multipleCustomersAssigned,
  siteIds
}) => {
  const [stateName, setStateName] = React.useState([] as any)
  const dispatch = useAppThunkDispatch()
  const { t } = useTranslation<any>()
  const [statusName, setStatusName] = React.useState([] as any)
  const [date, setDate] = React.useState<SReservation>({} as SReservation)
  const [region1, setRegion1] = React.useState([] as any)
  const [region2, setRegion2] = React.useState([] as any)
  const [region3, setRegion3] = React.useState([] as any)
  const [region4, setRegion4] = React.useState([] as any)
  const scheduleFilter = useSelector(
    (state: RootState) => state.schedule.scheduleFilters
  )
  const [filteredRegion1, setFilteredRegion1] = useState([] as any)
  const [filteredRegion2, setFilteredRegion2] = useState([] as any)
  const [filteredRegion3, setFilteredRegion3] = useState([] as any)
  const [filteredRegion4, setFilteredRegion4] = useState([] as any)
  const [countryName, setCountryName] = React.useState([] as any)
  const [siteIdSelects, setSiteIdSelects] = React.useState([] as any)

  const handleChange = (event, id) => {
    handleChangeRegion(
      event,
      id,
      setRegion1,
      setRegion2,
      setRegion3,
      setRegion4
    )
  }
  const handleChangeCountry = event => {
    handleChangeCountryVal(event, setCountryName)
  }
  const handleChangeSiteId = event => {
    handleChangeSiteIdVal(event, setSiteIdSelects)
  }
  const handleChangeStatus = event => {
    handleChangeStatusVal(event, setStatusName, statusName, uniqStatus)
  }

  const handleChangeState = event => {
    handleChangeStateVal(event, setStateName)
  }
  useEffect(() => {
    getRegionData(
      userData,
      setFilteredRegion1,
      setFilteredRegion2,
      setFilteredRegion3,
      setFilteredRegion4
    )
    setRegion1(scheduleFilter?.region1 || [])
    setRegion2(scheduleFilter?.region2 || [])
    setRegion3(scheduleFilter?.region3 || [])
    setRegion4(scheduleFilter?.region4 || [])
    setStateName(scheduleFilter?.state || [])
    setCountryName(scheduleFilter?.country || [])
    setStatusName(scheduleFilter?.eventStatus || [])
    setDate({
      scheduledDateTime: scheduleFilter?.currentDate
        ? scheduleFilter.currentDate
        : ""
    })
  }, [scheduleFilter])

  let filteredCountries = countries.filter(function (el) {
    return el != null || ""
  })
  let uniqCountries: any = Array.from(new Set(filteredCountries))
  uniqCountries = sortData(uniqCountries)
  let filteredStates = states.filter(function (el) {
    return el != null || ""
  })
  let uniqStates: any = Array.from(new Set(filteredStates))
  uniqStates = filterAndSort(uniqStates)

  let uniqSiteIds: any = Array.from(new Set(siteIds))

  const uniqStatus = React.useMemo(() => getEventStatusList({ t }), [t])

  const sortedStatus = uniqStatus
    .slice()
    .sort((a, b) => a.label.localeCompare(b.label))
  const isAllSelected = useMemo(
    () => uniqStatus.length > 0 && statusName.length === uniqStatus.length,
    [statusName, uniqStatus]
  )
  const applyFilterClick = () => {
    let filtersData = {
      region1: region1,
      region2: region2,
      region3: region3,
      region4: region4,
      country: countryName,
      siteIds: siteIdSelects,
      state: stateName,
      currentDate: date.scheduledDateTime
        ? moment(date.scheduledDateTime).format("YYYY-MM-DD")
        : "",
      eventStatus: statusName
    }
    dispatch(setScheduleFilterForm({ ...filtersData }))
    applyClick({
      ...filtersData
    })
    dispatch(setScheduleFilters({ ...filtersData }))
  }

  const onDateChange = e => {
    moment.locale("us_en")
    dateChangeHandler(e, setDate, date)
  }
  const applyCancelClick = () => {
    // dispatch(setScheduleFilterForm(prevFilters))
    cancelClick()
  }

  const handleResetAllClick = () => {
    handleResetAll({
      setRegion1,
      setRegion2,
      setRegion3,
      setRegion4,
      setCountryName,
      setStateName,
      setDate,
      setStatusName,
      isSchedulePage: true
    })
    setSiteIdSelects([])
  }
  const handleKeyDown = useCallback(
    event => {
      if (event.keyCode === 8 || event.keyCode === 46) {
        const newDate = { scheduledDateTime: "" }
        setDate(newDate)
      }
    },
    [setDate, date]
  )
  return (
    <>
      {showFilterPage ? (
        <FilterComponent
          t={t}
          Translates={Translates}
          storeData={storeData}
          multipleCustomersAssigned={multipleCustomersAssigned}
          handleChange={handleChange}
          filteredRegion1Values={filteredRegion1}
          filteredRegion2Values={filteredRegion2}
          filteredRegion3Values={filteredRegion3}
          filteredRegion4Values={filteredRegion4}
          region1={region1}
          region2={region2}
          region3={region3}
          region4={region4}
          MenuProps={MenuProps}
          countryName={countryName}
          handleChangeCountry={handleChangeCountry}
          uniqCountries={uniqCountries}
          stateName={stateName}
          handleChangeState={handleChangeState}
          uniqStates={uniqStates}
          date={date}
          onDateChange={onDateChange}
          handleKeyDown={handleKeyDown}
          statusName={statusName}
          handleChangeStatus={handleChangeStatus}
          isAllSelected={isAllSelected}
          sortedStatus={sortedStatus}
          handleResetAllClick={handleResetAllClick}
          applyCancelClick={applyCancelClick}
          applyFilterClick={applyFilterClick}
          uniqSiteIds={uniqSiteIds}
          siteIdSelects={siteIdSelects}
          handleChangeSiteId={handleChangeSiteId}
        />
      ) : null}
    </>
  )
}

export default ScheduleFilter
