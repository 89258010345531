import { useEffect } from "react"
import { useSelector } from "react-redux"
import {
  comparingConfigAndReduxArrays,
  getCountFieldFormateForConfig
} from "src/configurationPortal/pages/FunctionHelper"
import { ConfigTabNames } from "src/configurationPortal/pages/helper"
import { RootState } from "src/redux/store"
import { getConfigurationDecryptAndSetRedux } from "src/utils/helper"

export const ConfigTabNavigateWarning = ({
  componentName,
  isTabChangeCheck
}: {
  componentName: string
  isTabChangeCheck?: boolean
}) => {
  const { config, reportData, auth } = useSelector((state: RootState) => {
    return {
      config: state.config || {},
      reportData: state.report || {},
      auth: state.auth || {}
    }
  })

  const {
    configDetails,
    inputFiles,
    locationCategories,
    defaultCountFields,
    lookupRoutines,
    countPrograms,
    Outputs
  } = config
  const { reportsRedux } = reportData

  useEffect(() => {
    let isMounted = true
    const controller = new AbortController()
    if (
      isMounted &&
      configDetails?.configuration &&
      auth?.keys?.length &&
      (isTabChangeCheck || isTabChangeCheck === undefined)
    ) {
      const dt = getConfigurationDecryptAndSetRedux(config)
      if (componentName === ConfigTabNames.InputFiles) {
        comparingConfigAndReduxArrays(dt?.InputFiles, inputFiles)
      } else if (componentName === ConfigTabNames.Categories) {
        comparingConfigAndReduxArrays(
          dt?.LocationCategories,
          locationCategories
        )
      } else if (componentName === ConfigTabNames.CountFields) {
        let locConfigField = JSON.parse(JSON.stringify(dt?.Fields))
        let locField = JSON.parse(JSON.stringify(defaultCountFields))
        delete locConfigField?.countScreenTotals
        locConfigField = getCountFieldFormateForConfig(locConfigField)
        let fieldsObjCopy = getCountFieldFormateForConfig(locField)
        comparingConfigAndReduxArrays(locConfigField, fieldsObjCopy)
      } else if (componentName === ConfigTabNames.LookupRoutines) {
        comparingConfigAndReduxArrays(dt?.LookupRoutines, lookupRoutines)
      } else if (componentName === ConfigTabNames.CountPrograms) {
        comparingConfigAndReduxArrays(dt?.CountPrograms, countPrograms)
      } else if (componentName === ConfigTabNames.Reports) {
        comparingConfigAndReduxArrays(dt?.Reports, reportsRedux)
      } else if (componentName === ConfigTabNames.Outputs) {
        comparingConfigAndReduxArrays(dt?.Outputs, Outputs)
      }
    }
    return () => {
      isMounted = false
      controller.abort()
    }
    //eslint-disable-next-line
  }, [config, auth?.keys])

  return {
    config
  }
}
