import { Alert, AlertTitle, Button, Grid, TextField } from "@mui/material"
import React,{ useCallback, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { useHistory, useLocation } from "react-router"
import MiniHeader from "src/components/MiniHeader/MiniHeader"
import { RootState } from "src/redux/store"
import { UploadFileService } from "src/service/uploadfile.service"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import { Translates } from "src/i18n/i18n"
import { getTranslations } from "src/utils/helper"

const CreateEditUploadFileData = () => {
  const customerId = useSelector(
    (state: RootState) => state.settings?.selectedCustomerForOperation
  )
  const departmentData = useSelector(
    (state: RootState) => state.uploadFiles?.departmentData
  )
  const varianceData = useSelector(
    (state: RootState) => state.uploadFiles?.varianceData
  )
  const location: any = useLocation()
  const [edit, setEdit] = React.useState(false)
  const [saving, setSaving] = useState(false)
  const [alertVisible, setAlertVisible] = useState(false)
  const { t } = useTranslation<any>()
  const history = useHistory()
  const uploadService = new UploadFileService()
  const [departmentError, setDepartmentError] = useState(false)
  const [varianceError, setVarianceError] = useState(false)
  const [deptForm, setDeptForm] = useState({
    department: "",
    departmentName: "",
    departmentSort: "",
    departmentParentName: "",
    departmentParentSort: "",
    departmentParent: ""
  })
  const [varianceForm, setVarianceForm] = useState({
    varianceGroup: "",
    varianceGroupName: "",
    sortOrder: "",
    qtyTolerance: "",
    fullStoreQtyTolerance: "",
    extTolerance: "",
    fullStoreExtTolerance: ""
  })
  const [isDeptFile, setIsDeptFile] = useState(false)
  const [dataFromLocationState, setDataFromLocationState] = useState({} as any)
  useEffect(() => {
    let isMounted = true
    if (isMounted) {
      init()
    }
    return () => {
      isMounted = false
    }
  }, [customerId])
  useEffect(() => {
    let isMounted = true
    if (isMounted) {
      init()
    }
    return () => {
      isMounted = false
    }
  }, [])
  const init = () => {
    let dataId = location.state?.id
    let data = location.state?.Data
    let deptFile = location.state?.fileType
    setIsDeptFile(deptFile)
    setDataFromLocationState(location.state)
    if (dataId) {
      if (deptFile) {
        setDeptForm({
          department: data.department,
          departmentName: data.departmentName,
          departmentSort: data.departmentSort,
          departmentParentName: data.departmentParentName,
          departmentParentSort: data.departmentParentSort,
          departmentParent: data.departmentParent
        })
      } else {
        setVarianceForm({
          varianceGroup: data.varianceGroup,
          varianceGroupName: data.varianceGroupName,
          sortOrder: data.sortOrder,
          qtyTolerance: data.qtyTolerance,
          fullStoreQtyTolerance: data.fullStoreQtyTolerance,
          extTolerance: data.extTolerance,
          fullStoreExtTolerance: data.fullStoreExtTolerance
        })
      }
      setEdit(true)
    }
  }
  const handleCancel = () => {
    history.goBack()
  }

  const handleChange = (e, id) => {
    if (id == 1) {
      setDepartmentError(false)
      setDeptForm({
        ...deptForm,
        department: e.target.value
      })
    } else if (id == 2) {
      setDeptForm({
        ...deptForm,
        departmentName: e.target.value
      })
    } else if (id == 3) {
      setDeptForm({
        ...deptForm,
        departmentSort: e.target.value
      })
    } else if (id == 4) {
      setDeptForm({
        ...deptForm,
        departmentParentName: e.target.value
      })
    } else if (id == 5) {
      setDeptForm({
        ...deptForm,
        departmentParentSort: e.target.value
      })
    } else if (id == 6) {
      setDeptForm({
        ...deptForm,
        departmentParent: e.target.value
      })
    } else if (id == 7) {
      setVarianceError(false)
      setVarianceForm({
        ...varianceForm,
        varianceGroup: e.target.value
      })
    } else if (id == 8) {
      setVarianceForm({
        ...varianceForm,
        varianceGroupName: e.target.value
      })
    } else if (id == 9) {
      setVarianceForm({
        ...varianceForm,
        sortOrder: e.target.value
      })
    } else if (id == 10) {
      setVarianceForm({
        ...varianceForm,
        qtyTolerance: e.target.value
      })
    } else if (id == 11) {
      setVarianceForm({
        ...varianceForm,
        fullStoreQtyTolerance: e.target.value
      })
    } else if (id == 12) {
      setVarianceForm({
        ...varianceForm,
        extTolerance: e.target.value
      })
    } else {
      setVarianceForm({
        ...varianceForm,
        fullStoreExtTolerance: e.target.value
      })
    }
  }

  const handleSave = () => {
    saveData("SAVE")
  }

  const handleSaveAndNew = () => {
    saveData("SAVE_AND_NEW")
  }
  const saveData = (operation = "SAVE") => {
    if (isDeptFile) {
      let duplicateDept: any = departmentData.find((dept: any) => {
        if (deptForm?.department)
          return (
            dept?.department == deptForm?.department &&
            deptForm?.department != location.state?.Data?.department
          )
      })
      if (duplicateDept) {
        setDepartmentError(true)
      } else {
        setDepartmentError(false)
        sendPayload(operation)
      }
    } else {
      let duplicateVariance: any = varianceData.find((variance: any) => {
        if (varianceForm?.varianceGroup)
          return (
            variance?.varianceGroup == varianceForm?.varianceGroup &&
            varianceForm?.varianceGroup != location.state?.Data?.varianceGroup
          )
      })
      if (duplicateVariance) {
        setVarianceError(true)
      } else {
        setVarianceError(false)
        sendPayload(operation)
      }
    }
  }
  const sendPayload = (operation = "SAVE") => {
    setSaving(true)
    let genericPayload = {
      idConfig: dataFromLocationState.dataFromState?.idConfig,
      fileName: dataFromLocationState.dataFromState?.selectedFileType,
      idFileType: dataFromLocationState.dataFromState?.idFileType,
      customerId: customerId
    }
    let deptPayload = {
      department: deptForm?.department,
      departmentName: deptForm?.departmentName,
      departmentSort: Number(deptForm?.departmentSort),
      departmentParentName: deptForm?.departmentParentName,
      departmentParentSort: Number(deptForm?.departmentParentSort),
      departmentParent: deptForm?.departmentParent,
      customerId: customerId,
      validationDepartmentId: edit
        ? dataFromLocationState.Data?.validationDepartmentId
        : 0,
      validationControlId: edit
        ? dataFromLocationState.Data?.validationControlId
        : 0
    }
    let variancePayload = {
      varianceGroup: varianceForm.varianceGroup,
      varianceGroupName: varianceForm.varianceGroupName,
      sortOrder: Number(varianceForm.sortOrder),
      qtyTolerance: Number(varianceForm.qtyTolerance),
      fullStoreQtyTolerance: Number(varianceForm.fullStoreQtyTolerance),
      extTolerance: Number(varianceForm.extTolerance),
      fullStoreExtTolerance: Number(varianceForm.fullStoreExtTolerance),
      customerId: customerId,
      varianceControlId: edit
        ? dataFromLocationState.Data?.varianceControlId
        : 0,
      validationControlId: edit
        ? dataFromLocationState.Data?.validationControlId
        : 0
    }

    let payload: any = isDeptFile
      ? {
          ...genericPayload,
          validationDepartmentRequest: deptPayload,
          varianceControlRequest: null
        }
      : {
          ...genericPayload,
          varianceControlRequest: variancePayload,
          validationDepartmentRequest: null
        }
    if (edit) {
      uploadService
        .updateData(payload)
        .then((res: any) => {
          if (res && res.status == 200) {
            setSaving(false)
            setAlertVisible(true)
            setTimeout(() => {
              setAlertVisible(false)
              history.goBack()
            }, 1000)
          }
        })
        .catch(err => {
          setSaving(false)
          console.log("error in saving data ", err)
        })
    } else {
      uploadService
        .updateData(payload)
        .then((res: any) => {
          if (res && res.status == 200) {
            setSaving(false)
            setAlertVisible(true)
            if (operation == "SAVE_AND_NEW") {
              setTimeout(() => {
                setAlertVisible(false)
              }, 1000)
              resetForm()
            } else {
              setTimeout(() => {
                setAlertVisible(false)
                history.goBack()
              }, 1000)
            }
          }
        })
        .catch(err => {
          setSaving(false)
          console.log("error in saving data ", err)
        })
    }
  }
  const resetForm = () => {
    if (isDeptFile) {
      setDeptForm({
        department: "",
        departmentName: "",
        departmentSort: "",
        departmentParentName: "",
        departmentParentSort: "",
        departmentParent: ""
      })
    } else {
      setVarianceForm({
        varianceGroup: "",
        varianceGroupName: "",
        sortOrder: "",
        qtyTolerance: "",
        fullStoreQtyTolerance: "",
        extTolerance: "",
        fullStoreExtTolerance: ""
      })
    }
  }
  const getDisabledState = () => {
    if (isDeptFile) {
      if (deptForm?.department) {
        return false
      } else {
        return true
      }
    } else {
      if (varianceForm?.varianceGroup) {
        return false
      } else {
        return true
      }
    }
  }
  const exceptThisSymbols = ["e", "E", "+", "-", "."]

  const handleKeyDown= useCallback(e => {
    if (exceptThisSymbols.includes(e.key)) {
      e.preventDefault();
    }
  }, [])
  return (
    <Grid container>
      {alertVisible ? (
        <Alert
         data-testid="success-alert"
          severity="success"
          icon={<CheckCircleIcon />}
          className="custom-alert top"
        >
          <AlertTitle className="custom-alert__title f-600">
            {getTranslations(t, Translates.Success)}
          </AlertTitle>
          {edit ? (
            <p>{getTranslations(t, Translates.Data_updated_successfully)}</p>
          ) : (
            <p>{getTranslations(t, Translates.Data_added_successfully)}</p>
          )}
        </Alert>
      ) : null}
      <MiniHeader
        headerTitle={
          edit
            ? getTranslations(t, Translates.Edit_Data)
            : getTranslations(t, Translates.Create_Data)
        }
      />
      <Grid container className="inner-view">
        <Grid item xs={12} className="main-container">
          <Grid container>
            <Grid item xs={12}>
              <h4 className="inner-view__title">
                {getTranslations(t, Translates.DETAILS)}
              </h4>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={12} sm={3}>
                  {isDeptFile ? (
                    <>
                      <div className="text-box mb-2">
                        <TextField
                          fullWidth
                          name="department"
                          label={getTranslations(t, Translates.Department)}
                          variant="filled"
                          autoSave="false"
                          autoComplete="off"
                          className="custom-form-control"
                          value={deptForm?.department}
                          error={departmentError}
                          helperText={
                            departmentError ? "Department already exists" : ""
                          }
                          onChange={e => handleChange(e, 1)}
                          data-testid="department-input" // Add a data-testid attribute
                        />
                      </div>
                      <div className="text-box mb-2">
                        <TextField
                          fullWidth
                          name="departmentName"
                          label={getTranslations(t, Translates.Department_Name)}
                          variant="filled"
                          autoSave="false"
                          autoComplete="off"
                          className="custom-form-control"
                          value={deptForm?.departmentName}
                          onChange={e => handleChange(e, 2)}
                          data-testid="departmentName-input"
                        />
                      </div>
                      <div className="text-box mb-2">
                        <TextField
                          fullWidth
                          name="departmentSort"
                          type={"number"}
                          label={getTranslations(t, Translates.Department_Sort)}
                          variant="filled"
                          autoSave="false"
                          autoComplete="off"
                          className="custom-form-control"
                          value={deptForm?.departmentSort}
                          onKeyDown={handleKeyDown}
                          onChange={e => handleChange(e, 3)}
                          data-testid="departmentSort" // Add a data-testid attribute
                        />
                      </div>
                      <div className="text-box mb-2">
                        <TextField
                          fullWidth
                          name="departmentParentName"
                          label={getTranslations(
                            t,
                            Translates.Department_Parent_Name
                          )}
                          variant="filled"
                          autoSave="false"
                          autoComplete="off"
                          className="custom-form-control"
                          value={deptForm?.departmentParentName}
                          onChange={e => handleChange(e, 4)}
                          data-testid="departmentParentName" // Add a data-testid attribute
                        />
                      </div>
                      <div className="text-box mb-2">
                        <TextField
                          fullWidth
                          name="departmentSortParent"
                          type={"number"}
                          label={getTranslations(
                            t,
                            Translates.Department_Parent_Sort
                          )}
                          variant="filled"
                          autoSave="false"
                          autoComplete="off"
                          className="custom-form-control"
                          value={deptForm?.departmentParentSort}
                          onKeyDown={handleKeyDown}
                          onChange={e => handleChange(e, 5)}
                          data-testid="departmentSortParent" // Add a data-testid attribute
                        />
                      </div>
                      <div className="text-box">
                        <TextField
                          fullWidth
                          name="departmentParent"
                          label={getTranslations(
                            t,
                            Translates.Department_Parent
                          )}
                          variant="filled"
                          autoSave="false"
                          autoComplete="off"
                          className="custom-form-control"
                          value={deptForm?.departmentParent}
                          onChange={e => handleChange(e, 6)}
                          data-testid="departmentParent" // Add a data-testid attribute
                        />
                      </div>
                    </>
                  ) : null}
                  {!isDeptFile ? (
                    <>
                      <div className="text-box mb-2">
                        <TextField
                          fullWidth
                          name="varianceGroup"
                          label={getTranslations(t, Translates.Variance_Group)}
                          variant="filled"
                          autoSave="false"
                          autoComplete="off"
                          className="custom-form-control"
                          value={varianceForm?.varianceGroup}
                          error={varianceError}
                          helperText={
                            varianceError ? "Variance group already exists" : ""
                          }
                          onChange={e => handleChange(e, 7)}
                          data-testid="varianceGroup" // Add a data-testid attribute
                        />
                      </div>
                      <div className="text-box mb-2">
                        <TextField
                          fullWidth
                          name="varianceGroupName"
                          label={getTranslations(
                            t,
                            Translates.Variance_Group_Name
                          )}
                          variant="filled"
                          autoSave="false"
                          autoComplete="off"
                          className="custom-form-control"
                          value={varianceForm?.varianceGroupName}
                          onChange={e => handleChange(e, 8)}
                          data-testid="varianceGroupName" // Add a data-testid attribute
                        />
                      </div>
                      <div className="text-box mb-2">
                        <TextField
                          fullWidth
                          name="sortOrder"
                          type={"number"}
                          label={getTranslations(t, Translates.Sort_Order)}
                          variant="filled"
                          autoSave="false"
                          autoComplete="off"
                          className="custom-form-control"
                          value={varianceForm?.sortOrder}
                          onKeyDown={handleKeyDown}
                          onChange={e => handleChange(e, 9)}
                          data-testid="sortOrder" // Add a data-testid attribute
                        />
                      </div>
                      <div className="text-box mb-2">
                        <TextField
                          fullWidth
                          name="qtyTolerance"
                          type={"number"}
                          label={getTranslations(t, Translates.Qty_Tolerance)}
                          variant="filled"
                          autoSave="false"
                          autoComplete="off"
                          className="custom-form-control"
                          value={varianceForm?.qtyTolerance}
                          onKeyDown={handleKeyDown}
                          onChange={e => handleChange(e, 10)}
                          data-testid="qtyTolerance" // Add a data-testid attribute
                        />
                      </div>
                      <div className="text-box mb-2">
                        <TextField
                          fullWidth
                          name="fullStoreQtyTolerance"
                          type={"number"}
                          label={getTranslations(
                            t,
                            Translates.Full_Store_Qty_Tolerance
                          )}
                          variant="filled"
                          autoSave="false"
                          autoComplete="off"
                          className="custom-form-control"
                          value={varianceForm?.fullStoreQtyTolerance}
                          onKeyDown={handleKeyDown}
                          onChange={e => handleChange(e, 11)}
                          data-testid="fullStoreQtyTolerance" // Add a data-testid attribute
                        />
                      </div>
                      <div className="text-box mb-2">
                        <TextField
                          fullWidth
                          name="extTolerance"
                          type={"number"}
                          label={getTranslations(t, Translates.Ext_Tolerance)}
                          variant="filled"
                          autoSave="false"
                          autoComplete="off"
                          className="custom-form-control"
                          value={varianceForm?.extTolerance}
                          onKeyDown={handleKeyDown}
                          onChange={e => handleChange(e, 12)}
                          data-testid="extTolerance" // Add a data-testid attribute
                        />
                      </div>
                      <div className="text-box">
                        <TextField
                          fullWidth
                          name="fullStoreExtTolerance"
                          type={"number"}
                          label={getTranslations(
                            t,
                            Translates.Full_Store_Ext_Tolerance
                          )}
                          variant="filled"
                          autoSave="false"
                          autoComplete="off"
                          className="custom-form-control"
                          value={varianceForm?.fullStoreExtTolerance}
                          onKeyDown={handleKeyDown}
                          onChange={e => handleChange(e, 13)}
                          data-testid="fullStoreExtTolerance" // Add a data-testid attribute
                        />
                      </div>
                    </>
                  ) : null}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item sm={12}>
            <div className="inner-view__footer mt-5 mb-2">
              <Button
                variant="text"
                data-testid="Cancel"
                className="primary-btn mr-5 cancel-btn"
                onClick={handleCancel}
              >
                {getTranslations(t, Translates.Cancel)}
              </Button>
              <Button
                className="primary-btn"
                data-testid="Save"
                onClick={handleSave}
                variant="contained"
                disabled={getDisabledState() || saving}
              >
                {getTranslations(t, Translates.Save)}
              </Button>
              {!edit ? (
                <Button
                  variant="contained"
                  className="primary-btn ml-4"
                  data-testid="Save New"
                  onClick={handleSaveAndNew}
                  disabled={getDisabledState() || saving}
                >
                  {getTranslations(t, Translates.Save_New)}
                </Button>
              ) : null}
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default CreateEditUploadFileData
