import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { ConfigService } from "src/service/config.service"
import { initCountFields } from "src/configurationPortal/pages/CountFields/Constants"
import { IConfigCountProgram, IFormCountProgram } from "src/utils/types/types"

export interface ConfigState {
  status: string
  configDetails: any
  isGetConfigDecrypt: boolean
  generalSettings: any
  accuracySettings: any
  efficiencySettings: any
  locationCategories: any[]
  Reports: any[]
  countFields: any
  defaultCountFields: any
  configName: any
  configType: any
  lookupRoutines: any[]
  newLookupRoutine: any
  newReportPrompts: any
  newReports: any
  newSQLQuery: string
  newSQLQueryColumns: any[]
  newReportSQLQueryColumns: any[]
  Outputs: any[]
  currentOutputFile: any
  Audits: any[]
  newReportColumns: any
  countPrograms: any[]
  inputFiles: any[]
  editColumns: any[]
  outputColumns: any[]
  outputSubTotals: any[]
  outputGrandTotals: any[]
  fixedLengthInputFields: any[]
  delimitedInputFields: any[]
  excelInputFields: any[]
  customerFormValues: any
  reportSubTotals: any
  reportColumnError: boolean
  reportGrandTotals: any
  configDataHookCalled: boolean
  newCountProgram: IFormCountProgram
  newTargetLocationAuditRule: any[]
  newTargetRecordAuditRule: any[]
  newRandomAuditRule: any[]
  newFollowUpAuditRule: any[]
  warnOnTabChange: boolean
  customerConfigurations: any[]
  isGetConfigsDecrypt: boolean
  customerConfigurationsStatus: string
  reduxCountScreenTotals: string
  outputDestination: any
}
export const initialState: ConfigState = {
  status: "",
  configDetails: {},
  isGetConfigDecrypt: false,
  generalSettings: {},
  accuracySettings: {},
  efficiencySettings: {},
  locationCategories: [],
  Reports: [],
  countFields: {},
  defaultCountFields: { ...initCountFields },
  configName: "",
  configType: "",
  lookupRoutines: [],
  newLookupRoutine: {},
  newReportPrompts: [],
  newReports: {},
  newSQLQuery: "",
  newSQLQueryColumns: [],
  newReportSQLQueryColumns: [],
  Outputs: [],
  Audits: [],
  currentOutputFile: [],
  newReportColumns: [],
  countPrograms: [] as IConfigCountProgram[],
  inputFiles: [],
  editColumns: [],
  outputColumns: [],
  outputSubTotals: [],
  outputGrandTotals: [],
  fixedLengthInputFields: [],
  delimitedInputFields: [],
  excelInputFields: [],
  customerFormValues: {},
  reportSubTotals: [],
  reportColumnError: false,
  reportGrandTotals: [],
  configDataHookCalled: false,
  newTargetLocationAuditRule: [],
  newTargetRecordAuditRule: [],
  newRandomAuditRule: [],
  newFollowUpAuditRule: [],
  newCountProgram: {} as IFormCountProgram,
  warnOnTabChange: false,
  customerConfigurations: [],
  isGetConfigsDecrypt: false,
  customerConfigurationsStatus: "",
  reduxCountScreenTotals: "",
  outputDestination: {}
}
const configService = new ConfigService()
export const getConfiguration = createAsyncThunk(
  "configList/getConfiguration",
  async (configId: any) => {
    return configService.getConfiguration(configId)
  }
)
export const getConfigDetails = createAsyncThunk(
  "configList/getConfigDetails",
  async (customerId: any) => {
    return configService.getConfigDetails([...customerId].flat(Infinity))
  }
)
export const configSlice = createSlice({
  name: "configList",
  initialState,
  reducers: {
    setReduxCountScreenTotals: (state, _action: PayloadAction<any>) => {
      state.reduxCountScreenTotals = _action.payload
    },
    setConfigDetails: (state, _action: PayloadAction<any>) => {
      state.isGetConfigDecrypt = true
      state.configDetails = { ..._action.payload }
    },
    setGeneralSettings: (state, _action: PayloadAction<any>) => {
      state.generalSettings = { ..._action.payload }
    },
    setEfficiencySettings: (state, _action: PayloadAction<any>) => {
      state.efficiencySettings = { ..._action.payload }
    },
    setAccuracySettings: (state, _action: PayloadAction<any>) => {
      state.accuracySettings = { ..._action.payload }
    },
    setLocationCategories: (state, _action: PayloadAction<any>) => {
      state.locationCategories = [..._action.payload]
    },
    setReports: (state, _action: PayloadAction<any>) => {
      state.Reports = [..._action.payload]
    },
    setNewReportPrompts: (state, _action: PayloadAction<any>) => {
      state.newReportPrompts = [..._action.payload]
    },
    setNewReports: (state, _action: PayloadAction<any>) => {
      state.newReports = { ..._action.payload }
    },
    setNewReportColumns: (state, _action: PayloadAction<any>) => {
      state.newReportColumns = [..._action.payload]
    },
    setSQLQuery: (state, _action: PayloadAction<any>) => {
      state.newSQLQuery = _action.payload
    },
    setSQLQueryColumns: (state, _action: PayloadAction<any>) => {
      state.newSQLQueryColumns = _action.payload
    },
    setReportSQLQueryColumns: (state, _action: PayloadAction<any>) => {
      state.newReportSQLQueryColumns = _action.payload
    },
    setAudits: (state, _action: PayloadAction<any>) => {
      state.Audits = _action.payload
    },
    setNewTargetLocationAuditRule: (state, _action: PayloadAction<any>) => {
      state.newTargetLocationAuditRule = [..._action.payload]
    },
    setNewTargetRecordAuditRule: (state, _action: PayloadAction<any>) => {
      state.newTargetRecordAuditRule = [..._action.payload]
    },
    setNewRandomAuditRule: (state, _action: PayloadAction<any>) => {
      state.newRandomAuditRule = [..._action.payload]
    },
    setNewFollowUpAuditRule: (state, _action: PayloadAction<any>) => {
      state.newFollowUpAuditRule = [..._action.payload]
    },
    setCountFields: (state, _action: PayloadAction<any>) => {
      state.countFields = { ..._action.payload }
    },
    setCurrentOutputFile: (state, _action: PayloadAction<any>) => {
      state.currentOutputFile = { ..._action.payload }
    },
    setDefaultCountFields: (state, _action: PayloadAction<any>) => {
      let defaultCountFields = {
        ...state.countFields,
        ...state.defaultCountFields,
        ..._action.payload?.data
      }
      if (_action.payload?.type === "DELETE") {
        state.defaultCountFields = {
          ..._action.payload.data
        }
      } else {
        state.defaultCountFields = defaultCountFields
      }
    },
    setDefaultConfigName: (state, _action: PayloadAction<any>) => {
      state.configName = _action.payload
    },
    setDefaultConfigType: (state, _action: PayloadAction<any>) => {
      state.configType = _action.payload
    },
    setLookupRoutines: (state, _action: PayloadAction<any>) => {
      state.lookupRoutines = [..._action.payload]
    },
    setNewLookupRoutine: (state, _action: PayloadAction<any>) => {
      state.newLookupRoutine = { ..._action.payload }
    },

    setOutputFiles: (state, _action: PayloadAction<any>) => {
      state.Outputs = [..._action.payload]
    },
    setCountPrograms: (state, _action: PayloadAction<any>) => {
      state.countPrograms = [..._action.payload]
    },
    setInputFiles: (state, _action: PayloadAction<any>) => {
      state.inputFiles = [..._action.payload]
    },
    setEditColumns: (state, _action: PayloadAction<any>) => {
      state.editColumns = [..._action.payload]
    },
    setOutputColumns: (state, _action: PayloadAction<any>) => {
      state.outputColumns = [..._action.payload]
    },
    setOutputSubTotals: (state, _action: PayloadAction<any>) => {
      state.outputSubTotals = [..._action.payload]
    },
    setOutputGrandTotals: (state, _action: PayloadAction<any>) => {
      state.outputGrandTotals = [..._action.payload]
    },
    setFixedLengthInputFields: (state, _action: PayloadAction<any>) => {
      state.fixedLengthInputFields = [..._action.payload]
    },
    setDelimitedInputFields: (state, _action: PayloadAction<any>) => {
      state.delimitedInputFields = [..._action.payload]
    },
    setExcelInputFields: (state, _action: PayloadAction<any>) => {
      state.excelInputFields = [..._action.payload]
    },
    setCustomerFormValues: (state, _action: PayloadAction<any>) => {
      state.customerFormValues = { ..._action.payload }
    },
    setReportSubTotals: (state, _action: PayloadAction<any>) => {
      state.reportSubTotals = [..._action.payload]
    },
    setReportColumnError: (state, _action: PayloadAction<any>) => {
      state.reportColumnError = _action.payload
    },
    setReportGrandTotals: (state, _action: PayloadAction<any>) => {
      state.reportGrandTotals = [..._action.payload]
    },
    setConfigDataHookCalled: (state, _action: PayloadAction<any>) => {
      state.configDataHookCalled = _action.payload
    },
    setNewCountProgram: (state, _action: PayloadAction<any>) => {
      state.newCountProgram = { ..._action.payload }
    },
    updateWarnOnTabChange: (state, _action: PayloadAction<any>) => {
      state.warnOnTabChange = _action.payload
      return state
    },
    setCustomerConfiguration: (state, _action: PayloadAction<any>) => {
      state.customerConfigurations = _action.payload
      state.isGetConfigsDecrypt = true
      return state
    },
    setOutputDestination: (state, _action: PayloadAction<any>) => {
      state.outputDestination = { ..._action.payload }
    }
  },
  extraReducers: builder => {
    builder.addCase(getConfiguration.pending, state => {
      state.status = "loading"
    })
    //istanbul ignore next
    builder.addCase(getConfiguration.fulfilled, (state, { payload }) => {
      state.status = "success"
      state.isGetConfigDecrypt = false
      state.configDetails = payload.data
    })
    builder.addCase(getConfiguration.rejected, state => {
      state.status = "failed"
    })
    builder.addCase(getConfigDetails.pending, state => {
      state.customerConfigurationsStatus = "loading"
    })
    //istanbul ignore next
    builder.addCase(getConfigDetails.fulfilled, (state, { payload }) => {
      state.customerConfigurationsStatus = "success"
      state.isGetConfigsDecrypt = false
      state.customerConfigurations = payload.data || []
    })
    builder.addCase(getConfigDetails.rejected, state => {
      state.customerConfigurationsStatus = "failed"
    })
  }
})

export const {
  setConfigDetails,
  setGeneralSettings,
  setEfficiencySettings,
  setAccuracySettings,
  setLocationCategories,
  setReports,
  setCountFields,
  setDefaultCountFields,
  setDefaultConfigName,
  setDefaultConfigType,
  setLookupRoutines,
  setNewReportPrompts,
  setNewReports,
  setSQLQuery,
  setSQLQueryColumns,
  setReportSQLQueryColumns,
  setOutputFiles,
  setAudits,
  setNewReportColumns,
  setCountPrograms,
  setCurrentOutputFile,
  setInputFiles,
  setEditColumns,
  setOutputColumns,
  setOutputSubTotals,
  setOutputGrandTotals,
  setFixedLengthInputFields,
  setDelimitedInputFields,
  setExcelInputFields,
  setCustomerFormValues,
  setReportSubTotals,
  setReportColumnError,
  setReportGrandTotals,
  setConfigDataHookCalled,
  setNewCountProgram,
  setNewLookupRoutine,
  setNewTargetRecordAuditRule,
  setNewTargetLocationAuditRule,
  setNewRandomAuditRule,
  setNewFollowUpAuditRule,
  updateWarnOnTabChange,
  setCustomerConfiguration,
  setReduxCountScreenTotals,
  setOutputDestination
} = configSlice.actions

export default configSlice.reducer
