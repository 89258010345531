import { checkDigitOrAddCheckDigitInputs } from "./booleanFunctions"

import functionNames, {
  numberWithNotRequired,
  numberWithRequired,
  stringWithNotRequired,
  stringWithRequired
} from "./functionNames"

const functionTypes = functionNames
const SIDEBAR_ITEM = "sidebarItem"

const stringFunctions = [
  {
    type: SIDEBAR_ITEM,
    function: functionTypes.Trim,
    content: "Trim",
    parameters: [],
    inputType: [
      {
        type: "inputText",
        label: "Enter Text",
        name: "parameter1",
        validation: { ...stringWithNotRequired }
      }
    ]
  },
  {
    type: SIDEBAR_ITEM,
    function: functionTypes.LTrim,
    content: "Left Trim",
    parameters: [],
    inputType: [
      {
        type: "inputText",
        label: "Enter Text",
        name: "parameter1",
        validation: {
          ...stringWithNotRequired,
          ...{
            max: {
              max: 1,
              error: "Lift Trim character can not be more than 1"
            }
          }
        }
      }
    ]
  },
  {
    type: SIDEBAR_ITEM,
    function: functionTypes.RTrim,
    content: "Right Trim",
    parameters: [],
    inputType: [
      {
        type: "inputText",
        label: "Enter Text",
        name: "parameter1",
        validation: {
          ...stringWithNotRequired,
          ...{
            max: {
              max: 1,
              error: "Right Trim character can not be more than 1"
            }
          }
        }
      }
    ]
  },
  {
    type: SIDEBAR_ITEM,
    function: functionTypes.LPad,
    content: "Left Pad",
    parameters: [],
    inputType: [
      {
        type: "inputText",
        label: "Length*",
        name: "parameter1",
        validation: {
          ...numberWithRequired,
          ...{
            min: {
              min: 1,
              error: "Length should be more than 1"
            }
          }
        }
      },
      {
        type: "inputText",
        label: "Enter Text",
        name: "parameter2",
        validation: {
          ...stringWithNotRequired,
          ...{
            min: {
              min: 1,
              error: "Length should be 1(Single character)"
            },
            max: {
              max: 1,
              error: "Length should be 1(Single character)"
            }
          }
        }
      }
    ]
  },
  {
    type: SIDEBAR_ITEM,
    function: functionTypes.RPad,
    content: "Right Pad",
    parameters: [],
    inputType: [
      {
        type: "inputText",
        label: "Length*",
        name: "parameter1",
        validation: { ...numberWithRequired }
      },
      {
        type: "inputText",
        label: "Enter Text",
        name: "parameter2",
        validation: { ...stringWithNotRequired }
      }
    ]
  },
  {
    type: SIDEBAR_ITEM,
    function: functionTypes.SubString,
    content: "Sub String",
    parameters: [],
    inputType: [
      {
        type: "inputText",
        label: "Start Position*",
        name: "parameter1",
        defaultValue: 0,
        validation: { ...numberWithRequired }
      },
      {
        type: "inputText",
        label: "Length ",
        name: "parameter2",
        validation: { ...numberWithNotRequired }
      }
    ]
  },
  {
    type: SIDEBAR_ITEM,
    function: functionTypes.SubStringFromEnd,
    content: "Substring From End",
    parameters: [],
    inputType: [
      {
        type: "inputText",
        label: "Start Position*",
        name: "parameter1",
        defaultValue: 0,
        validation: { ...numberWithRequired }
      },
      {
        type: "inputText",
        label: "Length",
        name: "parameter2",
        validation: { ...numberWithNotRequired }
      }
    ]
  },
  {
    type: SIDEBAR_ITEM,
    function: functionTypes.ToUpper,
    content: "To Upper",
    parameters: []
  },
  {
    type: SIDEBAR_ITEM,
    function: functionTypes.ToLower,
    content: "To Lower",
    parameters: []
  },
  {
    type: SIDEBAR_ITEM,
    function: functionTypes.Remove,
    content: "Remove",
    parameters: [],
    inputType: [
      {
        type: "inputText",
        label: "Start Position*",
        name: "parameter1",
        defaultValue: 0,
        validation: { ...numberWithRequired }
      },
      {
        type: "inputText",
        label: "Length",
        name: "parameter2",
        validation: { ...numberWithNotRequired }
      }
    ]
  },
  {
    type: SIDEBAR_ITEM,
    function: functionTypes.Replace,
    content: "Replace",
    parameters: [],
    inputType: [
      {
        type: "inputText",
        label: "String To Replace*",
        name: "parameter1",
        validation: { ...stringWithRequired }
      },
      {
        type: "inputText",
        label: "String To Replace With*",
        name: "parameter2",
        validation: { ...stringWithRequired }
      }
    ]
  },
  {
    type: SIDEBAR_ITEM,
    function: functionTypes.RemoveFromEnd,
    content: "Remove From End",
    parameters: [],
    inputType: [
      {
        type: "inputText",
        label: "Start Position*",
        name: "parameter1",
        defaultValue: 0,
        validation: { ...numberWithRequired }
      },
      {
        type: "inputText",
        label: "Length",
        name: "parameter2",
        validation: { ...numberWithNotRequired }
      }
    ]
  },
  {
    type: SIDEBAR_ITEM,
    function: functionTypes.Strip,
    content: "Strip",
    parameters: [],
    inputType: [
      {
        type: "inputText",
        label: "Enter Text*",
        name: "parameter1",
        validation: { ...stringWithRequired }
      }
    ]
  },
  {
    type: SIDEBAR_ITEM,
    function: functionTypes.StripControlChars,
    content: "Strip Control Chars",
    parameters: []
  },
  {
    type: SIDEBAR_ITEM,
    function: functionTypes.Split,
    content: "Split",
    parameters: [],
    inputType: [
      {
        type: "inputText",
        label: "Delimiter*",
        name: "parameter1",
        validation: { ...stringWithRequired }
      },
      {
        type: "inputText",
        label: "Index To Keep*",
        name: "parameter2",
        defaultValue: 0,
        validation: { ...numberWithRequired }
      }
    ]
  },
  {
    type: SIDEBAR_ITEM,
    function: functionTypes.Insert,
    content: "Insert",
    parameters: [],
    inputType: [
      {
        type: "inputText",
        label: "Start Position*",
        name: "parameter1",
        validation: { ...numberWithRequired }
      },
      {
        type: "inputText",
        label: "String To Insert*",
        name: "parameter2",
        validation: { ...stringWithRequired }
      }
    ]
  },
  {
    type: SIDEBAR_ITEM,
    function: functionTypes.ExpandUpceToUpca,
    content: "Expand UPCE To UPCA",
    parameters: []
  },
  {
    type: SIDEBAR_ITEM,
    function: functionTypes.AddCheckDigit,
    content: "Add Check Digit",
    parameters: [],
    inputType: checkDigitOrAddCheckDigitInputs
  },
  {
    type: SIDEBAR_ITEM,
    function: functionTypes.RestoreOriginal,
    content: "Restore Original",
    parameters: []
  }
].sort((a, b) => {
  if (a.function > b.function) {
    return 1
  } else if (b.function > a.function) {
    return -1
  } else {
    return 0
  }
})

export default stringFunctions
