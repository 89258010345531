import {
  Alert,
  AlertTitle,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Tooltip
} from "@mui/material"
import React, { useCallback, useEffect, useState } from "react"
import { Loader } from "../../../src/utils/reusables/reusable"
import GlobalTable from "../../../src/components/GlobalTable/GlobalTable"
import { ScheduleService } from "../../../src/service/schedule.service"
import editIcon from "../../assets/images/Edit.png"
import ConfirmDialog from "../../../src/components/ConfirmDialog/ConfirmDialog"
import CheckIcon from "@mui/icons-material/Check"
import { useHistory } from "react-router-dom"
import RotateLeftIcon from "@mui/icons-material/RotateLeft"
import { useSelector } from "react-redux"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined"
import LockIcon from "@mui/icons-material/Lock"
import { RootState, useAppThunkDispatch } from "../../../src/redux/store"
import multiCustomerLogo from "../../../src/assets/images/MultiCustomer.png"
import {
  encryptUrlParams,
  filterByHelper,
  getCurrentUserRole,
  filterRegionalUserData,
  handleSuperVisorPortalRedirect,
  getTranslations,
  decryptUrlParams,
  millisecondsToDays,
  encrypt,
  decrypt,
  stripSecondsAndMilliseconds
} from "../../../src/utils/helper"
import Confirm from "../../../src/components/ConfirmDialog/Confirm"
import { useTranslation } from "react-i18next"
import { setCustomerForOperation } from "../../../src/redux/slices/settingsSlice"
import { SettingsService } from "../../../src/service/settings.service"
import ScheduleFilter from "./ScheduleFilter"
import { sortTableData } from "../../utils/helper"
import { fieldConstants, timeOut } from "../../utils/fieldConstants"
import { Warning } from "@mui/icons-material"
import PermissionsAccessGate from "../../../src/components/UserAccessMngt/PermissionAccessGate"
import {
  DESCENDING,
  FEATURES,
  KEY_CODES,
  PERMISSIONS,
  QUERY_PARAMETERS,
  ROLE_CONSTANTS,
  SCHEDULE_STATUS_CONSTANTS,
  SHIPPING_URL
} from "../../../src/utils/constants"
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined"
import ShippingIcon from "@mui/icons-material/LocalShippingOutlined"
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined"
import DateFormat from "src/components/DateFormat"
import ScheduleBanner from "./ScheduleBanner"
import OpenInNewOutlinedIcon from "@mui/icons-material/OpenInNewOutlined"
import { Translates } from "src/i18n/i18n"
import { getRegionalGroups } from "src/configurationPortal/pages/Variances/Utils/FunctionsHelper"
import moment from "moment"

const ScheduleDetails: React.FC<any> = ({
  showFilterPage,
  hideFilter,
  sendFilterDataToParent,
  setScheduleInParent,
  futureEventsData = [],
  selectedEventIds = [],
  actionIdConfig = 0,
  isInstall
}) => {
  const customerList = useSelector(
    (state: RootState) => state.settings.customers
  )
  const selectedCustomerList = customerList.filter((c: any) => c.selected)
  const multipleCustomersAssigned = selectedCustomerList.length > 1
  let filters: any = []
  let filtered: any = []
  const scheduleSortFilter = useSelector(
    (state: RootState) => state.schedule.scheduleSort
  )
  const scheduleColumnFilter = useSelector(
    (state: RootState) => state.schedule.scheduleColumn
  )
  const scheduleService = new ScheduleService()
  const { t } = useTranslation<any>()
  const history = useHistory()
  const [loading, setLoading] = useState(true)
  const [schedule, setSchedule] = useState<null | any[]>(null)
  const [alertVisible, setAlertVisible] = useState(false)
  const [isDialog, setIsDialog] = useState(false)
  const [storeData, setStoreData] = useState({} as any)
  const settingsService = new SettingsService()
  const [regionLabels, setRegionLabels] = useState<any>([])
  const [countries, setCountries] = useState([] as any)
  const [siteIds, setSiteIds] = useState([] as any)
  const [states, setStates] = useState([] as any)
  const [status, setStatus] = useState([] as any)
  const [userAccount, setUserAccount] = useState([] as any)
  const [currentDate, setCurrentDate] = useState("" as any)
  const [renderData, setRenderData] = useState([] as any)
  const [eventIds, setEventIds] = useState([] as any)
  const [selectAllCheckbox, setSelectAllCheckbox] = useState(false)
  const [isDeleteOpen, setIsDeleteOpen] = useState(false)
  const [alertContent, setAlertContent] = useState("")
  const [deleteRow, setDeleteRow] = useState({} as any)
  const [cancelRow, setCancelRow] = useState({} as any)
  const [fetchingPassword, setFetchingPassword] = useState<any>([])
  const dispatch = useAppThunkDispatch()
  const _userRole: string = getCurrentUserRole()
  let regionalGroupsInfo
  const scheduleFilterForm = useSelector(
    (state: RootState) => state.schedule.scheduleFilterForm
  )

  const customerId = useSelector(
    (state: RootState) => state.settings.selectedCustomerForOperation
  )
  const resStatus = 200
  const resDate = 7
  useEffect(() => {
    // get the regional user mapping
    regionalGroupsInfo =
      getCurrentUserRole("USER_REGIONS") &&
      JSON.parse(getCurrentUserRole("USER_REGIONS"))
    if (futureEventsData.length === 0 && actionIdConfig === 0) {
      let search = window.location.search
      const searchParams = new URLSearchParams(search)
      const EventIds = decryptUrlParams(
        searchParams.get(QUERY_PARAMETERS.EVENTIDS)
      )
      if (EventIds) {
        setEventIds(EventIds.split(",").map(str => parseInt(str, 10)))
      }
    }
  }, [])
  useEffect(() => {
    if (futureEventsData.length > 0) {
      getLocationsByStore()
    }
  }, [customerList, customerId, futureEventsData])

  const init = () => {
    if (futureEventsData.length === 0 && actionIdConfig === 0) {
      GetViewSchedule()
    } else {
      // from futureevent
      setEventIds(selectedEventIds)
      resProcess({ data: futureEventsData })
    }
  }

  useEffect(() => {
    if (customerList?.length) {
      init()
    } else {
      setTimeout(() => {
        init()
      })
    }
  }, [customerList, customerId])

  useEffect(() => {
    if (
      scheduleFilterForm?.country?.length ||
      scheduleFilterForm?.state?.length ||
      scheduleFilterForm?.currentDate !== "" ||
      scheduleFilterForm?.region1?.length ||
      scheduleFilterForm?.region2?.length ||
      scheduleFilterForm?.region3?.length ||
      scheduleFilterForm?.region4?.length ||
      scheduleFilterForm?.eventStatus
    ) {
      getAppliedValues(scheduleFilterForm)
    }
  }, [scheduleFilterForm, schedule, storeData])

  const DateFunction = (days: any) => {
    const result = new Date()
    result.setDate(result.getDate() + days)
    return result
  }

  const renderShareIcon = props => {
    const shareDisable =
      props.row.original.eventStatus?.toLowerCase() ===
      SCHEDULE_STATUS_CONSTANTS.Cancelled.toLowerCase()
    return (
      <Tooltip arrow title={`${getTranslations(t, "Event_Details")}`}>
        <InfoOutlinedIcon
          data-testid="shareIcon"
          className={
            shareDisable
              ? "mr-1 cursor-pointer disabled"
              : "mr-1 cursor-pointer"
          }
          onClick={() => handleShare(props.row.original)}
        />
      </Tooltip>
    )
  }

  const renderRedirectIcon = props => {
    const redirectDisable =
      props.row.original.eventStatus?.toLowerCase() ===
        SCHEDULE_STATUS_CONSTANTS.Cancelled.toLowerCase() ||
      fetchingPassword.includes(props.row.original.idEvent)
    return (
      <Tooltip
        arrow
        title={`${getTranslations(t, "Redirect")}`}
        data-testid="redirectIcon"
      >
        <OpenInNewOutlinedIcon
          className={
            redirectDisable
              ? "mr-1 cursor-pointer disabled"
              : "mr-1 cursor-pointer"
          }
          onClick={() => handleRedicrect(props.row.original)}
        />
      </Tooltip>
    )
  }

  const handleConciseView = record => {
    history.push(`/finalReportsAndFiles`, {
      eventDetails: record
    })
  }

  const renderEditIcon = props => {
    if (_userRole?.toLowerCase() === ROLE_CONSTANTS.WisUser.toLowerCase()) {
      return props.row.original.scheduledDateTime &&
        props.row.original.eventStatus?.toLowerCase() ===
          SCHEDULE_STATUS_CONSTANTS.NotStarted.toLowerCase() ? (
        <PermissionsAccessGate
          feature={FEATURES.SCHEDULE}
          permissionKey={PERMISSIONS.UPDATE}
        >
          <Tooltip arrow title={`${getTranslations(t, Translates.Edit)}`}>
            <img
              data-testid="edit"
              src={editIcon}
              alt={"edit schedule icon"}
              className="mr-1 cursor-pointer"
              width="20"
              onClick={() => handleEdit(props.row.original)}
              onKeyUp={e => {
                handleEditKeyUp(e, props.row.original)
              }}
            />
          </Tooltip>
        </PermissionsAccessGate>
      ) : null
    } else if (
      _userRole?.toLowerCase() !== ROLE_CONSTANTS.WisUser.toLowerCase()
    ) {
      if (
        new Date(props.row.original.scheduledDateTime) > DateFunction(7) &&
        props.row.original.eventStatus?.toLowerCase() ===
          SCHEDULE_STATUS_CONSTANTS.Future.toLowerCase()
      ) {
        return (
          <PermissionsAccessGate
            feature={FEATURES.SCHEDULE}
            permissionKey={PERMISSIONS.UPDATE}
          >
            <Tooltip arrow title={`${getTranslations(t, Translates.Edit)}`}>
              <img
                src={editIcon}
                alt={"edit schedule icon"}
                className="mr-1 cursor-pointer"
                width="20"
                onClick={() => handleEdit(props.row.original)}
                onKeyUp={e => {
                  handleEditKeyUp(e, props.row.original)
                }}
              />
            </Tooltip>
          </PermissionsAccessGate>
        )
      }
    }
    return null
  }

  const handleDelete = useCallback((idEvent: any) => {
    deleteSchedule(idEvent)
  }, [])
  const handleClick = useCallback((props: any) => {
    setCancelRow(props?.row?.original)
    handleDelete(props?.row?.original?.idEvent)
  }, [])

  const RenderDeleteIcon = (props: any) => {
    const renderDelete =
      props.row.original.eventStatus?.toLowerCase() ===
        SCHEDULE_STATUS_CONSTANTS.Completed.toLowerCase() ||
      props.row.original.eventStatus?.toLowerCase() ===
        SCHEDULE_STATUS_CONSTANTS.Cancelled.toLowerCase() ||
      (_userRole?.toLowerCase() === ROLE_CONSTANTS.WisUser.toLowerCase() &&
        new Date() >= new Date(props.row.original.scheduledDateTime))
    const renderDeleteData =
      (_userRole?.toLowerCase() !== ROLE_CONSTANTS.WisUser.toLowerCase() &&
        new Date(props.row.original.scheduledDateTime) <=
          DateFunction(resDate)) ||
      props.row.original.eventStatus?.toLowerCase() ===
        SCHEDULE_STATUS_CONSTANTS.Cancelled.toLowerCase()
    return (
      <Tooltip arrow title={`${getTranslations(t, Translates.Cancel)}`}>
        <CancelOutlinedIcon
          data-testid="delete"
          className={
            renderDelete || renderDeleteData
              ? "delete-icon disabled"
              : "delete-icon"
          }
          onClick={() => handleClick(props)}
        />
      </Tooltip>
    )
  }
  const resProcess = (temp: any) => {
    for (let i = 0; i < temp.data.length; i++) {
      temp.data[i].isTime = temp.data[i].scheduledDateTime
    }
    // filter the data if region is assigned
    if (
      regionalGroupsInfo?.length > 0 &&
      _userRole?.toLowerCase() === ROLE_CONSTANTS.RegionalUser.toLowerCase()
    ) {
      temp.data = filterRegionalUserData(temp.data, regionalGroupsInfo)
    }
    setSchedule(temp.data)
    setScheduleInParent(temp.data)
    setUserAccount(temp.data)

    setRenderData(
      sortTableData(
        temp.data?.filter(f =>
          (scheduleFilterForm?.eventStatus || []).includes(f.eventStatus)
        ),
        fieldConstants.SortOrder.ASC,
        fieldConstants.SortColumns.scheduledDateTime,
        fieldConstants.SortColumnType.Date
      )
    )
    setSiteIds(temp.data.filter(i => !!i.siteId).map((c: any) => c.siteId))
    setCountries(temp.data.map((c: any) => c.country))
    setStates(temp.data.map((c: any) => c.state))
    setStatus(temp.data.map((c: any) => c.eventStatus))
    setCurrentDate(temp.data.map((c: any) => c.scheduledDateTime))
    setTimeout(() => {
      setLoading(false)
    }, timeOut)
  }

  const GetViewSchedule = () => {
    const payloadToAdd = selectedCustomerList.map((c: any) => c.idCustomer)
    setLoading(true)
    getLocationsByStore()
      .then(() => {
        scheduleService
          .getviewschedule(payloadToAdd)
          .then((temp: any) => {
            resProcess(temp)
          })

          .catch(_error => {
            setLoading(false)
            console.error("Schedule page error", _error)
          })
      })
      .catch(err => {
        setLoading(false)
        console.error("Schedule page error", err)
      })
  }

  const getLocationsByStore = () => {
    const customerIds = selectedCustomerList?.map(i => i.idCustomer)
    return new Promise<any>((resolve, reject) => {
      settingsService
        .getLocationsByStore(customerIds)
        .then((temp: any) => {
          setStoreData(temp.data)
          let labels: any = []

          temp.data.forEach(i => {
            if (i.regionalGroup1Label)
              labels.push({ label: i.regionalGroup1Label, num: 1 })
            if (i.regionalGroup2Label)
              labels.push({ label: i.regionalGroup2Label, num: 2 })
            if (i.regionalGroup3Label)
              labels.push({ label: i.regionalGroup3Label, num: 3 })
            if (i.regionalGroup4Label)
              labels.push({ label: i.regionalGroup4Label, num: 4 })
          })
          setRegionLabels(labels)
          return resolve(true)
        })
        .catch(_error => {
          setLoading(false)
          console.error("Schedule page error", _error)
          return reject(_error)
        })
    })
  }

  const handleEdit = record => {
    let encryptedId: any = encryptUrlParams(record.idEvent)
    dispatch(setCustomerForOperation(record.idCustomer))
    history.push(`/schedule/edit/${encryptedId}`)
  }
  const handleEditKeyUp = useCallback((e: any, record: any) => {
    if (e.keyCode === KEY_CODES.ENTER) {
      handleEdit(record)
    }
  }, [])

  const handleShare = record => {
    let encryptedId: any = encryptUrlParams(record.idEvent)
    dispatch(setCustomerForOperation(record.idCustomer))
    history.push(`/schedule/eventdetails/${encryptedId}`, {
      eventDetails: record
    })
  }

  const openInNewTab = url => {
    window.open(url, "_blank", "noreferrer")
  }

  const handleShipping = record => {
    let shipURL: any =
      record.shipTrackingNo.length > 12
        ? SHIPPING_URL.UPS_URL
        : SHIPPING_URL.FEDEX_URL
    openInNewTab(shipURL + record.shipTrackingNo)
  }

  const removeFromFetching = id => {
    setFetchingPassword(fetchingPassword.filter(f => !id))
  }

  const handleRedicrect = record => {
    const fetching = [...fetchingPassword, record.idEvent]
    setFetchingPassword(fetching)
    dispatch(setCustomerForOperation(record.idCustomer))
    scheduleService
      .getEventPasswords(record.idEvent)
      .then(res => {
        const decyptedResp = JSON.parse(decrypt(res.data))

        record["password"] = encrypt(decyptedResp?.SupervisorPassword)
        removeFromFetching(record.idEvent)
        handleSuperVisorPortalRedirect(record, "schedule")
      })
      .catch(err => {
        removeFromFetching(record.idEvent)
      })
  }

  const renderCompanyCell = idCustomer => {
    let customer: any = selectedCustomerList.find(
      (c: any) => c.idCustomer == idCustomer
    )
    if (customer) {
      return (
        <div className="multi-cust-logo-cell">
          <img
            className="multi-cust-logo"
            src={customer.companyLogo || multiCustomerLogo}
            alt={"logo"}
          ></img>
          <span className="multi-cust-cname">{customer.companyName}</span>
        </div>
      )
    }
    return "NA"
  }
  const refreshSchedule = () => {
    GetViewSchedule()
  }
  const deleteSchedule = (row: any) => {
    setIsDeleteOpen(true)
    setDeleteRow(row)
  }

  const scheduleActionsResponse = (res: any, msg: string) => {
    if (res && res.status === resStatus) {
      setAlertVisible(true)
      setAlertContent(msg)
      refreshSchedule()
      setTimeout(() => {
        setAlertVisible(false)
      }, 1000)
    }
  }

  const callbackDeleteConfirmation = useCallback(
    (confirmationResp: boolean) => {
      if (confirmationResp) {
        scheduleService
          .deleteschedule(deleteRow)
          .then(resp => {
            setIsDeleteOpen(false)
            scheduleActionsResponse(
              resp,
              getTranslations(t, Translates.Schedule_Cancelled_Successfully)
            )
          })
          .catch(err => err)
      } else {
        setIsDeleteOpen(confirmationResp)
      }
    },
    [deleteRow]
  )

  const setContent = () => {
    const today: any = new Date()
    const scheduledDate: any = new Date(cancelRow?.scheduledDateTime)
    const differenceInMilliseconds: any = scheduledDate - today
    const daysBetween = millisecondsToDays(differenceInMilliseconds)
    if (
      _userRole?.toLowerCase() === ROLE_CONSTANTS.WisUser.toLowerCase() &&
      daysBetween <= resDate
    ) {
      return (
        <Alert severity="error" icon={<Warning />} className="custom-alert">
          <AlertTitle className="alert__title">
            <h2>{getTranslations(t, Translates.Warning)}</h2>
          </AlertTitle>
          <p>
            <strong className="d-block">{`${getTranslations(
              t,
              Translates.Devices_may_have_already_shipped_Confirm_cancellation_with
            )} ${moment(scheduledDate).format("MM/DD/YYYY")}?`}</strong>
          </p>
        </Alert>
      )
    } else {
      return (
        <Alert severity="error" icon={<Warning />} className="custom-alert">
          <AlertTitle className="alert__title">
            <h2>{getTranslations(t, Translates.Warning)}</h2>
          </AlertTitle>
          <p>
            <strong className="d-block">
              {getTranslations(
                t,
                Translates.This_action_will_cancel_this_event_Do_you_wish_to_proceed
              )}{" "}
            </strong>
          </p>
        </Alert>
      )
    }
  }

  const getStatusColor = eventStatus => {
    if (
      eventStatus?.toLowerCase() ===
      SCHEDULE_STATUS_CONSTANTS.Completed.toLowerCase()
    ) {
      return "success"
    } else if (
      eventStatus?.toLowerCase() ===
      SCHEDULE_STATUS_CONSTANTS.InProgress.toLowerCase()
    ) {
      return "warning"
    } else if (
      eventStatus?.toLowerCase() ===
      SCHEDULE_STATUS_CONSTANTS.NotStarted.toLowerCase()
    ) {
      return "not-started"
    } else if (
      eventStatus?.toLowerCase() ===
      SCHEDULE_STATUS_CONSTANTS.Cancelled.toLowerCase()
    ) {
      return "error"
    } else if (
      eventStatus?.toLowerCase() ===
      SCHEDULE_STATUS_CONSTANTS.Locked.toLowerCase()
    ) {
      return "error"
    } else {
      return "warning"
    }
  }
  const getIcon = eventStatus => {
    if (
      eventStatus?.toLowerCase() ===
      SCHEDULE_STATUS_CONSTANTS.Completed.toLowerCase()
    ) {
      return (
        <>
          {" "}
          <CheckIcon /> {getTranslations(t, Translates.Closed)}
        </>
      )
    } else if (
      eventStatus?.toLowerCase() ===
      SCHEDULE_STATUS_CONSTANTS.InProgress.toLowerCase()
    ) {
      return (
        <>
          {" "}
          <RotateLeftIcon />
          {getTranslations(t, Translates.In_Progress)}{" "}
        </>
      )
    } else if (
      eventStatus?.toLowerCase() ===
      SCHEDULE_STATUS_CONSTANTS.Cancelled.toLowerCase()
    ) {
      return (
        <>
          <CancelOutlinedIcon />
          {getTranslations(t, Translates.Cancelled)}{" "}
        </>
      )
    } else if (
      eventStatus?.toLowerCase() ===
      SCHEDULE_STATUS_CONSTANTS.Locked.toLowerCase()
    ) {
      return (
        <>
          <LockIcon />
          {getTranslations(t, Translates.Locked)}{" "}
        </>
      )
    } else if (
      eventStatus?.toLowerCase() ===
      SCHEDULE_STATUS_CONSTANTS.NotStarted.toLowerCase()
    ) {
      return <>{getTranslations(t, Translates.Not_Started)} </>
    }
  }
  const displayConfigName = (props: any) => {
    return (
      <span className="d-block text-left">
        {props.value ? props.value : "-"}{" "}
        {props.row.original.configVersion
          ? `(${props.row.original.configVersion})`
          : ""}
      </span>
    )
  }
  const getFullAddress = (props: any) => {
    return (
      <p>
        {props.row.original.address1 ? props.row.original.address1 : "-"},
        <br />
        {props.row.original.address2 ? props.row.original.address2 + "," : null}
        {props.row.original.address2 ? <br /> : null}
        {props.row.original.address3 ? props.row.original.address3 + "," : null}
        {props.row.original.address3 ? <br /> : null}
        {props.row.original.city ? props.row.original.city : "-"},&nbsp;
        {props.row.original.state ? props.row.original.state : "-"},<br />
        {props.row.original.country ? props.row.original.country : "-"}
        ,&nbsp;
        {props.row.original.postalCode ? props.row.original.postalCode : "-"}
      </p>
    )
  }
  const getAriaLabel = props => {
    let addressTool: any = []
    addressTool.push(
      props.row.original.address1 ? props.row.original.address1 : "-"
    )
    addressTool.push(
      props.row.original.address2 ? props.row.original.address2 : "-"
    )
    addressTool.push(
      props.row.original.address3 ? props.row.original.address3 : "-"
    )
    addressTool.push(props.row.original.city ? props.row.original.city : "-")
    addressTool.push(props.row.original.state ? props.row.original.state : "-")
    addressTool.push(
      props.row.original.country ? props.row.original.country : "-"
    )
    addressTool.push(
      props.row.original.postalCode ? props.row.original.postalCode : "-"
    )
    addressTool.join(" , ")
    return addressTool.toString()
  }
  const getAddress = (props: any) => {
    return (
      <p>
        {props.row.original.city ? props.row.original.city : "-"},&nbsp;
        {props.row.original.state ? props.row.original.state : "-"}
      </p>
    )
  }
  const onSelectChange = (event, props: any) => {
    if (!event.currentTarget.checked) {
      setSelectAllCheckbox(false)
      setEventIds(eventIds?.filter(id => id !== props.row.original.idEvent))
    } else {
      const temp = [
        ...eventIds.filter(f => f !== "-1"),
        props.row.original.idEvent
      ]

      setEventIds(temp)
      if (temp.length === renderData.length) {
        setSelectAllCheckbox(true)
      }
    }
  }

  const handleSelectAllHeader = e => {
    if (e.target.checked) {
      setSelectAllCheckbox(true)
      setEventIds([...renderData.map(m => m.idEvent)])
    } else {
      setSelectAllCheckbox(false)
      setEventIds([])
    }
  }
  // For not started and future events only we need to enable checkbox
  const showCheckbox = (props: any) => {
    return (
      props?.row?.original?.eventStatus?.toLowerCase() ===
        SCHEDULE_STATUS_CONSTANTS.NotStarted.toLowerCase() &&
      new Date(props?.row?.original.scheduledDateTime) >= DateFunction(0)
    )
  }

  let customSort = (rowA, rowB) => {
    const valueA: any = stripSecondsAndMilliseconds(
      rowA.original.scheduledDateTime
    )
    const valueB: any = stripSecondsAndMilliseconds(
      rowB.original.scheduledDateTime
    )

    // Sort by date
    const result = valueA - valueB

    // If dates are equal, sort by siteId in ascending order
    if (result === 0) {
      const a = parseInt(rowA.values["siteId"])
      const b = parseInt(rowB.values["siteId"])
      return a - b
    } else {
      return result
    }
  }

  const columnsData = React.useMemo(
    () => [
      {
        // For Schedule page we dont require select all checkbox in grid header
        Header: actionIdConfig !== 0 && (
          <Checkbox
            checked={selectAllCheckbox}
            onChange={handleSelectAllHeader}
            className="custom-check light"
            checkedIcon={<CheckIcon />}
            title=""
          />
        ),
        accessor: "s", // accessor is the "key" in the data
        disableFilters: true,
        disableSortBy: true,
        setWidth: 10,
        columnType: "schedule",
        Cell: props => {
          return (
            <span>
              <FormControlLabel
                label=""
                disabled={!showCheckbox(props)}
                control={
                  <Checkbox
                    className={"custom-check light"}
                    checked={eventIds?.includes(props?.row?.original?.idEvent)}
                    onChange={e => onSelectChange(e, props)}
                    checkedIcon={<CheckIcon />}
                  />
                }
              />
            </span>
          )
        }
      },
      {
        Header: "CUSTOMER",
        accessor: "idCustomer", // accessor is the "key" in the data
        headerClassName: "temp_class",
        disableFilters: true,
        disableSortBy: false,
        setWidth: 150,
        columnType: "schedule",
        Cell: props => {
          return renderCompanyCell(props.value)
        }
      },
      {
        Header: getTranslations(t, Translates.DATE),
        accessor: "scheduledDateTime", // accessor is the "key" in the data
        headerClassName: "temp_class",
        disableFilters: true,
        disableSortBy: false,
        "data-testid": "customerHeader",
        setWidth: 150,
        columnType: "schedule",
        sortType: customSort,
        Cell: props => {
          return <DateFormat {...props} /> // event date should not convert to local time
        }
      },
      {
        Header: getTranslations(t, Translates.Site_ID),
        accessor: "siteId", // accessor is the "key" in the data
        headerClassName: "temp_class",
        disableFilters: true,
        disableSortBy: false,
        setWidth: 200,
        columnType: "schedule",
        Cell: props => {
          return props.value ? props.value : "-"
        }
      },
      {
        Header: getTranslations(t, Translates.ADDRESS),
        accessor: "address1", // accessor is the "key" in the data
        headerClassName: "temp_class",
        disableFilters: true,
        disableSortBy: false,
        setWidth: 300,
        columnType: "schedule",
        Cell: props => (
          <Tooltip
            arrow
            placement="bottom-start"
            id="addressTooltip"
            aria-label={getAriaLabel(props)}
            title={getFullAddress(props)}
          >
            {getAddress(props)}
          </Tooltip>
        )
      },
      {
        Header: getTranslations(t, Translates.NO_OF_DEVICES),
        accessor: "numberofDevices", // accessor is the "key" in the data
        headerClassName: "temp_class",
        disableFilters: true,
        disableSortBy: false,
        setWidth: 120,
        columnType: "schedule",
        Cell: props => {
          return props.value ? props.value : "-"
        }
      },

      {
        Header: getTranslations(t, Translates.REGION),
        accessor: "region1", // accessor is the "key" in the data
        headerClassName: "temp_class",
        disableFilters: true,
        disableSortBy: false,
        setWidth: 250,
        columnType: "schedule",
        Cell: props => {
          const customerDetail = storeData.find(
            i => i.idCustomer === props?.row?.original?.idCustomer
          )
          return customerDetail
            ? getRegionalGroups(
                customerDetail.regionalGroup1Label,
                customerDetail.regionalGroup2Label,
                customerDetail.regionalGroup3Label,
                customerDetail.regionalGroup4Label,
                props?.row?.original
              )
            : null
        }
      },
      {
        Header: getTranslations(t, Translates.CONFIG_NAME),
        accessor: "configName", // accessor is the "key" in the data
        headerClassName: "temp_class",
        disableFilters: true,
        disableSortBy: false,
        setWidth: 220,
        columnType: "schedule",
        Cell: props => {
          return displayConfigName(props)
        }
      },
      {
        Header: getTranslations(t, Translates.RESERVATION),
        accessor: "reservationName", // accessor is the "key" in the data
        headerClassName: "temp_class",
        disableFilters: true,
        disableSortBy: false,
        setWidth: 220,
        columnType: "schedule",
        Cell: props => {
          return props.value ? props.value : "-"
        }
      },
      {
        Header: getTranslations(t, Translates.EVENT_STATUS),
        accessor: "eventStatus", // accessor is the "key" in the data
        headerClassName: "temp_class",
        disableFilters: true,
        disableSortBy: false,
        setWidth: 230,
        columnType: "schedule",
        Cell: props => {
          return (
            <div className={`status-bg   ${getStatusColor(props.value)}`}>
              {getIcon(props.value)}
            </div>
          )
        }
      },

      {
        Header: getTranslations(t, Translates.ACTIONS),
        accessor: "action", // accessor is the "key" in the data
        headerClassName: "temp_class",
        disableFilters: true,
        disableSortBy: true,
        setWidth: 200,
        columnType: "schedule",
        Cell: props => {
          return (
            <span className="action-btns  d-block ">
              {renderRedirectIcon(props)}
              {renderShareIcon(props)}
              {renderEditIcon(props)}
              {props.row.original.shipTrackingNo != null ? (
                <Tooltip
                  arrow
                  title={`${getTranslations(t, "Device_Shipping_Information")}`}
                >
                  <ShippingIcon
                    className="mr-1"
                    data-testid="shippingIcon"
                    onClick={() => handleShipping(props.row.original)}
                  />
                </Tooltip>
              ) : null}
              {props.row.original.eventStatus?.toLowerCase() ===
              SCHEDULE_STATUS_CONSTANTS.Completed.toLowerCase() ? (
                <Tooltip
                  arrow
                  title={`${getTranslations(t, Translates.View_Files)}`}
                >
                  <RemoveRedEyeOutlinedIcon
                    className="mr-1"
                    data-testid="conciseview"
                    onClick={() => handleConciseView(props.row.original)}
                  />
                </Tooltip>
              ) : null}
              {RenderDeleteIcon(props)}
            </span>
          )
        }
      }
    ],
    [
      setSchedule,
      deleteRow,
      renderData,
      t,
      eventIds.length,
      selectAllCheckbox,
      eventIds
    ]
  )

  const getColumns = () => {
    if (selectedCustomerList && selectedCustomerList.length > 1) {
      return columnsData
    }
    if (columnsData[1].Header === "CUSTOMER") {
      columnsData.splice(1, 1)
    }
    return columnsData
  }

  const cancelClick = () => {
    hideFilter()
  }

  const getAppliedValues = filteredData => {
    sendFilterDataToParent(filteredData)
    if (filteredData.siteIds && filteredData.siteIds.length > 0) {
      filters.push({
        field: "siteId",
        value: filteredData.siteIds,
        func: "equalString"
      })
    }
    if (filteredData.country && filteredData.country.length > 0) {
      filters.push({
        field: "country",
        value: filteredData?.country,
        func: "equalString"
      })
    }
    if (filteredData.state && filteredData.state.length > 0) {
      filters.push({
        field: "state",
        value: filteredData?.state,
        func: "equalString"
      })
    }
    if (filteredData.currentDate !== "") {
      filters.push({
        field: "scheduledDateTime",
        value: moment(filteredData?.currentDate)?.format("YYYY-MM-DD"),
        func: "equalDate"
      })
    }
    if (filteredData.eventStatus && filteredData.eventStatus.length > 0) {
      filters.push({
        field: "eventStatus",
        value: filteredData?.eventStatus,
        func: "equals"
      })
    }
    if (filteredData.region1 && filteredData.region1.length > 0) {
      filters.push({
        field: "region1",
        value: filteredData?.region1,
        func: "equalString"
      })
    }
    if (filteredData.region2 && filteredData.region2.length > 0) {
      filters.push({
        field: "region2",
        value: filteredData?.region2,
        func: "equalString"
      })
    }
    if (filteredData.region3 && filteredData.region3.length > 0) {
      filters.push({
        field: "region3",
        value: filteredData?.region3,
        func: "equalString"
      })
    }
    if (filteredData.region4 && filteredData.region4.length > 0) {
      filters.push({
        field: "region4",
        value: filteredData?.region4,
        func: "equalString"
      })
    }

    filtered = filterByHelper(filters, schedule, {
      labels: [...regionLabels],
      path: ["userRoles", "userRegions"]
    })

    setRenderData(
      sortTableData(
        filtered,
        fieldConstants.SortOrder.ASC,
        fieldConstants.SortColumns.scheduledDateTime,
        fieldConstants.SortColumnType.Date
      )
    )

    hideFilter()
  }

  const handleUpdateConfig = () => {
    const _temp = renderData?.filter(
      (m: any) =>
        m?.eventStatus?.toLowerCase() ===
          SCHEDULE_STATUS_CONSTANTS.NotStarted.toLowerCase() &&
        new Date(m.scheduledDateTime) >= DateFunction(0)
    )
    let encryptedId: any = encryptUrlParams(eventIds)
    history.push(`/updateConfiguration/${encryptedId}`, {
      futureEventCount: _temp?.length,
      actionIdConfig: actionIdConfig ? actionIdConfig : 0,
      isInstall: isInstall ? isInstall : false
    })
  }
  const checkUpdateConfigBtn = useCallback(() => {
    if (actionIdConfig === 0 && !eventIds?.length) {
      return true
    }
    if (actionIdConfig !== 0 && eventIds?.includes("-1")) {
      return true
    }
    if (
      actionIdConfig !== 0 &&
      ((eventIds?.length === 1 && eventIds?.includes("-1")) ||
        eventIds?.length === 0)
    ) {
      return true
    } else {
      return false
    }
  }, [actionIdConfig, eventIds])
  const displayGrid = () => {
    if (schedule && schedule.length > 0) {
      return (
        <>
          <div className="text-right mb-2" data-testid="updateConfig">
            <Button
              variant="contained"
              className="primary-btn"
              onClick={handleUpdateConfig}
              data-testid="updateConfig"
              disabled={checkUpdateConfigBtn()}
            >
              {getTranslations(t, Translates.UPDATE_CONFIGURATION)}
            </Button>
          </div>
          <Grid
            container
            className="table-responsive table-scrollable-container"
          >
            <Grid className="table-last-column-sticky">
              <GlobalTable
                data-testid="responsiveness"
                columns={getColumns()}
                data={renderData || []}
                noDataText={getTranslations(t, Translates.No_Events_Found)}
                initialStateProp={{
                  sortBy: [
                    {
                      id: "scheduledDateTime",
                      desc: scheduleSortFilter === DESCENDING.Desc
                    },
                    {
                      id: "siteId",
                      desc: scheduleSortFilter === DESCENDING.Desc
                    }
                  ]
                }}
              ></GlobalTable>

              {isDeleteOpen && (
                <ConfirmDialog
                  open={isDeleteOpen}
                  callBack={callbackDeleteConfirmation}
                  title={`${getTranslations(t, Translates.CANCEL_SCHEDULE)}`}
                  content={setContent}
                  custref={{}}
                  confirmButtonText={getTranslations(t, Translates.CONFIRM)}
                  cancelButtonText={getTranslations(t, Translates.Cancel)}
                ></ConfirmDialog>
              )}
              {isDialog && (
                <Confirm
                  open={isDialog}
                  callBack={() => {
                    setIsDialog(false)
                  }}
                  title={`${getTranslations(t, Translates.CANCELLED)}`}
                  content={setContent}
                  custref={{}}
                  confirmButtonText={getTranslations(t, Translates.CLOSE)}
                ></Confirm>
              )}
            </Grid>
          </Grid>
        </>
      )
    } else if (schedule && schedule.length === 0) {
      return <ScheduleBanner />
    } else {
      return <></>
    }
  }

  return (
    <>
      {loading ? <Loader /> : displayGrid()}
      {alertVisible ? (
        <Alert
          data-testid="success-alert"
          severity="success"
          icon={<CheckCircleIcon />}
          className="custom-alert top"
        >
          <AlertTitle className="custom-alert__title f-600">
            {getTranslations(t, Translates.Success)}
          </AlertTitle>
          {alertContent}
        </Alert>
      ) : null}
      {
        <ScheduleFilter
          countries={countries}
          siteIds={siteIds}
          currentDate={currentDate}
          showFilterPage={showFilterPage}
          states={states}
          status={status}
          applyClick={getAppliedValues}
          storeData={storeData}
          userData={userAccount}
          cancelClick={cancelClick}
          multipleCustomersAssigned={multipleCustomersAssigned}
        />
      }
    </>
  )
}
export default ScheduleDetails
