export const INPUT_FILE_ACTION_TYPE = {
  EDIT: "EDIT",
  ADD: "ADD"
}
export const INPUT_FILE_TYPE = {
  Auxiliary: "Auxiliary",
  CustomerFile: "Customer File",
  Delimited: "Delimited",
  Excel: "Excel",
  FixedLength: "FixedLength"
}
export const FileTypeList = [
  { label: "Department", value: "Department" },
  { label: "Variance Control", value: "VarianceControl" },
  { label: "Custom", value: "Custom" }
]
export const Auxiliary_Fields = {
  FileType: "FileType",
  Name: "Name",
  Description: "Description",
  LegacyProcessingCheck: "LegacyProcessingCheck",
  LegacyLocation: "LegacyLocation",
  CustomerMaintenanceCheck: "CustomerMaintenanceCheck",
  CustomRangeCheck: "CustomRangeCheck"
}
export const Customer_Fields = {
  Name: "Name",
  Description: "Description",
  LegacyLocation: "LegacyLocation",
  CustomerPortalCheck: "CustomerPortalCheck",
  InStoreCheck: "InStoreCheck",
  FileName: "FileName",
  Field: "Field",
  FileType: "FileType",
  HeaderRow: "HeaderRow",
  FieldDelimiter: "FieldDelimiter",
  RecordDelimiter: "RecordDelimiter",
  SurroundCharacter: "SurroundCharacter",
  ASCIICharValue: "ASCIICharValue"
}
export const Customer_Input_Fields = {
  Name: "Name",
  DataType: "DataType",
  AllowedLengths: "AllowedLengths",
  Regex: "Regex",
  ErrorLevel: "ErrorLevel",
  Length: "Length"
}
export const FileTypeRadioValueList = [
  { label: "Fixed Length", value: "FixedLength", noDataText: "NO FIXED LENGTH DATA AVAILABLE" },
  { label: "Delimited", value: "Delimited", noDataText: "NO DELIMITED DATA AVAILABLE"  },
  { label: "Excel", value: "Excel", noDataText: "NO EXCEL DATA AVAILABLE"  }
]
export const FieldDelimiterList = ["None", "Tab", "Comma", "Pipe", "Colon"]
export const RecordDelimiterList = ["CRLF", "LF", "CR", "None", "ASCIIChar(x)"]
export const ErrorLevelList = ["ERROR", "WARN"]
export const DataTypeList = ["NUMERIC", "DECIMAL", "TEXT"]
export const nameErrorText = "Name already exists"
export const helperTextSelect = 'Please select at least one of the upload locations'
export const fileTypeSelectText = 'Please select at least one of the file type'
export const newCustomerFileText = "New Customer File"
export const newAuxiliaryFileText = "New Auxiliary File"
export const editField = "Edit Field"
export const addField = "Add Field"
export const tables = {
  Validation_Department: "Validation_Department",
  VarianceControl: "VarianceControl"
}
