import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { CustomerService } from "src/service/customer.service"

export interface UsersState {
  status: string
  userFilters: any
  userPrevFilters: any
  validUserColumnList: string[]
  csvUserColumnList: string[]
  userMappingData: any
  userFileData: any
  userFilePath: string
  userAccounts: any[]
  usersData: any
  usersSort: any
  usersColumn: any
}

const initialState: UsersState = {
  status: "",
  userFilters: {
    region1: [],
    region2: [],
    region3: [],
    region4: [],
    phone: "",
    sPhone: "",
    email: "",
    status: false
  },
  userPrevFilters: {
    region1: [],
    region2: [],
    region3: [],
    region4: [],
    phone: "",
    sPhone: "",
    email: "",
    status: false
  },
  validUserColumnList: [
    "First Name",
    "Last Name",
    "Region 1",
    "Region 2",
    "Region 3",
    "Region 4",
    "User Type",
    "Primary Phone Number",
    "Secondary Phone Number",
    "Email ID"
  ],
  csvUserColumnList: [],
  userMappingData: {},
  userFileData: "",
  userFilePath: "",
  userAccounts: [],
  usersData: [],
  usersSort: "",
  usersColumn: ""
}
const customerService = new CustomerService()
export const getUserAccounts = createAsyncThunk(
  "users/accounts",
  async (userEmail: any) => {
    return customerService.getAccountsByEmail(userEmail)
  }
)
export const usersSlice = createSlice({
  name: "usersList",
  initialState,
  reducers: {
    setUserFilters: (state, _action: PayloadAction<any>) => {
      state.userFilters = { ...state.userFilters, ..._action.payload }
    },
    setUserPrevFilters: (state, _action: PayloadAction<any>) => {
      state.userPrevFilters = { ...state.userPrevFilters, ..._action.payload }
    },
    setCSVUserColumnList: (state, _action: PayloadAction<any>) => {
      state.csvUserColumnList = [..._action.payload]
    },
    setUserMappingData: (state, _action: PayloadAction<any>) => {
      state.userMappingData = { ..._action.payload }
    },
    setUserFileData: (state, _action: PayloadAction<any>) => {
      state.userFileData = _action.payload
    },
    setUserFilePath: (state, _action: PayloadAction<any>) => {
      state.userFilePath = _action.payload
    },
    setUsersData: (state, _action: PayloadAction<any>) => {
      state.usersData = [..._action.payload]
    },
    setUsersColumn: (state, _action: PayloadAction<any>) => {
      state.usersColumn = _action.payload
    },
    setUsersSort: (state, _action: PayloadAction<any>) => {
      state.usersSort = _action.payload
    },
    setUserReset: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(getUserAccounts.pending, state => {
      state.status = "loading"
    })
    //istanbul ignore next
    builder.addCase(getUserAccounts.fulfilled, (state, { payload }) => {
      state.status = "success"
      state.userAccounts = payload.data
    })
    builder.addCase(getUserAccounts.rejected, state => {
      state.status = "failed"
    })
  }
})
export const {
  setUserFilters,
  setUserPrevFilters,
  setCSVUserColumnList,
  setUserMappingData,
  setUserFileData,
  setUserFilePath,
  setUsersData,
  setUserReset,
  setUsersColumn,
  setUsersSort
} = usersSlice.actions
export default usersSlice.reducer
