import { ReportFieldType, VarianceType } from "./Type"

export const NoGroupVarianceFieldsText = "No Group Variance Fields Found"
export const NoFullVarianceFieldsText = "No Full Store Variance Fields Found"
export const EmptyNameError = "Variance name cannot be empty"

export const VarianceTypes = {
  GroupVariance: {
    name: "GroupVariance",
    label: "Group_Variance"
  },
  FullVariance: {
    name: "FullVariance",
    label: "Full_variance"
  }
}

export const singleFieldNames = {
  IsEnable: "IsEnable",
  VarianceName: "VarianceName",
  Sort: "Sort",
  IsBatchSize: "IsBatchSize",
  BatchSize: "BatchSize",
  IsBlindVariance: "IsBlindVariance",
  IsRequiredForCloseout: "IsRequiredForCloseout",
  IsBasedOnControlFile: "IsBasedOnControlFile",
  Threshold: "Threshold",
  QtyThreshold: "QtyThreshold",
  ExtThreshold: "ExtThreshold",
  QtyConstant: "QtyConstant",
  ExtConstant: "ExtConstant",
  ReportFields: "ReportFields"
}

export const getVarianceConstant = ({
  VarianceType
}: {
  VarianceType: string
}) => {
  return {
    IsEnable: {
      name: `{VarianceType}.${singleFieldNames.IsEnable}`.replace(
        "{VarianceType}",
        VarianceType
      ),
      label: "Enable_Feature"
    },
    VarianceName: {
      name: `{VarianceType}.${singleFieldNames.VarianceName}`.replace(
        "{VarianceType}",
        VarianceType
      ),
      label: "Variance_Name"
    },
    Sort: {
      name: `{VarianceType}.${singleFieldNames.Sort}`.replace(
        "{VarianceType}",
        VarianceType
      ),
      label: "Sort"
    },
    IsBatchSize: {
      name: `{VarianceType}.${singleFieldNames.IsBatchSize}`.replace(
        "{VarianceType}",
        VarianceType
      ),
      label: "Batch_Size"
    },
    BatchSize: {
      name: `{VarianceType}.${singleFieldNames.BatchSize}`.replace(
        "{VarianceType}",
        VarianceType
      ),
      label: "Size"
    },
    IsBlindVariance: {
      name: `{VarianceType}.${singleFieldNames.IsBlindVariance}`.replace(
        "{VarianceType}",
        VarianceType
      ),
      label: "Blind_Variance"
    },
    IsRequiredForCloseout: {
      name: `{VarianceType}.${singleFieldNames.IsRequiredForCloseout}`.replace(
        "{VarianceType}",
        VarianceType
      ),
      label: "Required_for_Closeout"
    },
    IsBasedOnControlFile: {
      name: `{VarianceType}.${singleFieldNames.IsBasedOnControlFile}`.replace(
        "{VarianceType}",
        VarianceType
      ),
      label: "Based_on_control_file"
    },
    Threshold: {
      name: `{VarianceType}.${singleFieldNames.Threshold}`.replace(
        "{VarianceType}",
        VarianceType
      ),
      label: "Threshold"
    },
    QtyThreshold: {
      name: `{VarianceType}.${singleFieldNames.QtyThreshold}`.replace(
        "{VarianceType}",
        VarianceType
      ),
      label: "Threshold"
    },
    ExtThreshold: {
      name: `{VarianceType}.${singleFieldNames.ExtThreshold}`.replace(
        "{VarianceType}",
        VarianceType
      ),
      label: "Threshold"
    },
    QtyConstant: {
      name: `{VarianceType}.${singleFieldNames.QtyConstant}`.replace(
        "{VarianceType}",
        VarianceType
      ),
      label: "Threshold"
    },
    ExtConstant: {
      name: `{VarianceType}.${singleFieldNames.ExtConstant}`.replace(
        "{VarianceType}",
        VarianceType
      ),
      label: "Threshold"
    },
    ReportFields: {
      name: `{VarianceType}.${singleFieldNames.ReportFields}`.replace(
        "{VarianceType}",
        VarianceType
      )
    }
  }
}
//All label values Translate variable from en.json file
export const fieldNames = {
  CurrentTab: "CurrentTab",
  CurrentTabVarianceType: "CurrentTabVarianceType",
  GroupVariance: getVarianceConstant({
    VarianceType: VarianceTypes.GroupVariance.name
  }),
  FullVariance: getVarianceConstant({
    VarianceType: VarianceTypes.FullVariance.name
  })
}

export const SortList = [
  { label: "Location", value: "Location", default: true },
  { label: "SKU", value: "SKU" },
  { label: "Qty Variance (ABS)", value: "Qty Variance (ABS)" },
  { label: "Ext Variance (ABS)", value: "Ext Variance (ABS)" }
]

export const GroupAndFullVarianceCommon = {
  IsEnable: false,
  VarianceName: "",
  Sort: SortList[0].value, //Location
  IsBatchSize: false,
  BatchSize: 0,
  IsBlindVariance: false,
  IsRequiredForCloseout: false,
  Threshold: "",
  QtyThreshold: "",
  QtyConstant: 0,
  ExtThreshold: "",
  ExtConstant: 0,
  ReportFields: []
}

export const defaultIniValues: VarianceType = {
  CurrentTab: 0,
  CurrentTabVarianceType: VarianceTypes.GroupVariance.name,
  GroupVariance: GroupAndFullVarianceCommon,
  FullVariance: {
    ...GroupAndFullVarianceCommon,
    ...{ IsBasedOnControlFile: false }
  }
}

export const defaultReportFieldIniValues: ReportFieldType = {
  Field: "",
  Name: ""
}

export const RadioButtonLists = {
  Thresholds: {
    Qty: {
      value: "Qty",
      label: "Qty"
    },
    Ext: {
      value: "Ext",
      label: "Ext"
    },
    QtyOrExt: {
      value: "QtyOrExt",
      label: "Qty OR Ext"
    },
    QtyAndExt: {
      value: "QtyAndExt",
      label: "Qty AND Ext"
    }
  },
  ThresholdSelects: {
    VarianceControlFile: {
      value: "Variance Control File",
      label: "Variance Control File"
    },
    OnHandsFile: {
      value: "On Hands File",
      label: "On Hands File"
    },
    Constant: {
      value: "Constant",
      label: "Constant"
    }
  }
}
/*usa and canada phone numbers validation*/
export const phoneRegexValidation = /^\+1\(\d{3}\)\d{3}-\d{4}$/

/* converting phone number to +# (###) ###-#### */
export const convertPhoneNumberRegexStart = /^1?(\d{3})(\d{3})(\d{4})$/
export const convertPhoneNumberRegexEnd = "+1($1) $2-$3"

export const ReportFieldName = {
  Field: {
    name: "Field",
    label: "Field"
  },
  Name: {
    name: "Name",
    label: "Name"
  }
}

export const BlindVFieldOptions = [
  { value: "SKU", label: "SKU" },
  { value: "Barcode", label: "Barcode" },
  { value: "Location", label: "Location" },
  { value: "Price", label: "Price" },
  { value: "Qty", label: "Qty" },
  { value: "Ext", label: "Ext" }
]
export const NonBlindVFieldOptions = [
  { value: "On Hands Ext", label: "On Hands Ext" },
  { value: "On Hands Qty", label: "On Hands Qty" },
  { value: "Qty Variance", label: "Qty Variance" },
  { value: "Ext Variance", label: "Ext Variance" }
]

export const ReportFieldOptions = [
  ...BlindVFieldOptions,
  ...NonBlindVFieldOptions
].flat(Infinity)

export const ReportGridModalSource = {
  delete: "delete",
  blindVariance: "blindVariance"
}
export const MAP_FILTERS = [
  {
    index: 1,
    filterFn: item => item.countProgress === 0
  },
  {
    index: 2,
    filterFn: item => item.countProgress > 0 && item.countProgress < 100
  },
  {
    index: 3,
    filterFn: item => item.countProgress === 100
  },
  {
    index: 4,
    filterFn: item => item.auditProgress === 0
  },
  {
    index: 5,
    filterFn: item => item.auditProgress > 0 && item.auditProgress < 100
  },
  {
    index: 6,
    filterFn: item => item.auditProgress === 100
  },
  {
    index: 7,
    filterFn: item => item.varianceProgress === 0
  },
  {
    index: 8,
    filterFn: item => item.varianceProgress > 0 && item.varianceProgress < 100
  },
  {
    index: 9,
    filterFn: item => item.varianceProgress === 100
  }
]
