import { createSlice, PayloadAction } from "@reduxjs/toolkit"

interface Templates {
  LOCATION: string
  RESERVATION: string
  SCHEDULE: string
  USER: string
  DEPARTMENT: string
  VARIANCE: string
}
export interface AuthState {
  isAutheticated: boolean
  loggedInEmail: string | null
  keys: Array<any>
  templates: Templates
}

const initialState: AuthState = {
  isAutheticated: false,
  loggedInEmail: localStorage.getItem("username"),
  keys: [],
  templates: {
    LOCATION: "",
    RESERVATION: "",
    SCHEDULE: "",
    USER: "",
    DEPARTMENT: "",
    VARIANCE: ""
  }
}

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: state => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      localStorage.removeItem("token")
      state.isAutheticated = false
    },
    login: state => {
      state.isAutheticated = true
    },
    setLoginEmail: (state, _action: PayloadAction<string>) => {
      state.loggedInEmail = _action.payload
      return state
    },
    setKeys: (state, _action: PayloadAction<any>) => {
      state.keys = [..._action.payload]
    },
    setTemplates: (state, _action: PayloadAction<any>) => {
      state.templates = {..._action.payload}
    }
  }
})

// Action creators are generated for each case reducer function
export const { logout, login, setLoginEmail, setKeys, setTemplates } = authSlice.actions

export default authSlice.reducer
