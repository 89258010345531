import { useEffect, useState } from "react"
import {
  Grid,
  FormControlLabel,
  Checkbox,
  Divider,
  Button
} from "@mui/material"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined"
import MiniHeader from "../../../src/components/MiniHeader/MiniHeader"
import CheckIcon from "@mui/icons-material/Check"
import CloseIcon from "@mui/icons-material/Close"
import { Loader } from "../../../src/utils/reusables/reusable"
import { useHistory } from "react-router-dom"
import { UserService } from "../../../src/service/user.service"
import { setCustomers } from "../../../src/redux/slices/settingsSlice"
import ImageIcon from "@mui/icons-material/Image"
import { getUserAccounts } from "src/redux/slices/usersSlice"
import { RootState, useAppThunkDispatch } from "src/redux/store"
import { setCustomerConfiguration } from "src/redux/slices/configSlice"
import { useTranslation } from "react-i18next"
import { Translates } from "src/i18n/i18n"
import { getTranslations } from "src/utils/helper"
import { useSelector } from "react-redux"

const SelectCustomer = (props: any) => {
  const history = useHistory()
  const { t } = useTranslation<any>()
  const dispatch = useAppThunkDispatch()
  const userService = new UserService()
  const isRedirectHome = useSelector(
    (state: RootState) => state.customerSelection.isRedirectHome
  )
  const [customersList, setCustomersList] = useState([] as any)
  const [saving, setSaving] = useState(false)
  const [recentCustomers, setRecentCustomers] = useState([] as any)
  const [disableContinue, setDisableContinue] = useState(false)
  const [customersToPayload, setCustomersToPayload] = useState([] as any)
  const [customersRefList, setCustomersRefList] = useState([] as any)
  const [loading, setLoading] = useState(false)
  const userEmail = localStorage.getItem("username") ?? ""

  const getCustomerList = () => {
    setLoading(true)
    dispatch(getUserAccounts(userEmail))
      .then((temp: any) => {
        setUserDetails(temp.payload?.data)
      })
      .catch(err => {
        setLoading(false)
      })
  }
  const setUserDetails = data => {
    let res = data || []
    res = res
      .filter(d => d.companyName)
      .map((c: any) => {
        return {
          name: c.companyName,
          img: c.companyLogo,
          selected: c.isCustomerSelected,
          idCustomer: c.idCustomer,
          lastUpdatedDate: c.lastUpdatedDate
        }
      })

    let recent: any = [...res]
    res = res.sort((a: any, b: any) => {
      if (a.name.toLowerCase() > b.name.toLowerCase()) return 1
      if (b.name.toLowerCase() > a.name.toLowerCase()) return -1
      return 0
    })
    recent = recent.sort((a: any, b: any) => {
      const dateComparison: any =
        new Date(b.lastUpdatedDate).getTime() -
        new Date(a.lastUpdatedDate).getTime()
      if (dateComparison !== 0) return dateComparison
      return a.selected ? -1 : 1
    })
    recent = recent.slice(0, 7)
    setCustomersRefList(res)
    setCustomersList(res)
    setRecentCustomers(recent)
    dispatch(setCustomers(res))
    setLoading(false)
  }

  useEffect(() => {
    getCustomerList()
  }, [])

  const handleCancel = () => {
    if (props.close) {
      props.close()
    }
  }
  const handleContinue = async () => {
    setSaving(true)
    let customerList: any = []
    customersToPayload.forEach((c: any) => {
      let refObj = customersRefList.find(
        (rf: any) => rf.idCustomer === c.idCustomer
      )
      if (refObj && refObj.isCustomerSelected !== c.selected) {
        customerList.push({
          idCustomer: c.idCustomer,
          isSelected: !!c.selected
        })
      }
    })
    customerList = customerList.sort((a, b) => a.isSelected - b.isSelected)
    let payload = {
      email: userEmail,
      customers: customerList
    }
    try {
      await userService.saveCustomerSelection([payload])
      dispatch(setCustomers(customersList))
      dispatch(setCustomerConfiguration([]))
      dispatch(getUserAccounts(userEmail))
        .then(() => {
          setSaving(false)
          if (props.close) {
            props.close()
          }
          isRedirectHome && history.push("/")
        })

        .catch(
          //istanbul ignore next
          () => setLoading(false)
        )
    } catch {
      //istanbul ignore next
      setSaving(false)
    }
  }

  const handleTileClick = (event, cust) => {
    let customerList: any = [...customersList]
    let recentCust: any = [...recentCustomers]
    let ctpList: any = [...customersToPayload]
    let findIndex = customerList.findIndex(
      (c: any) => c.name.toLowerCase() == cust.name.toLowerCase()
    )
    if (findIndex != -1) {
      customerList[findIndex] = {
        ...customerList[findIndex],
        selected: event.target.checked
      }
      let findPayloadIndex = ctpList.findIndex(
        (ctp: any) => ctp.idCustomer == customerList[findIndex].idCustomer
      )
      if (findPayloadIndex != -1)
        ctpList[findPayloadIndex] = customerList[findIndex]
      else ctpList.push(customerList[findIndex])
      setCustomersToPayload(ctpList)
    }
    let rcfindIndex = recentCust.findIndex(
      (c: any) => c.name.toLowerCase() == cust.name.toLowerCase()
    )
    if (rcfindIndex != -1) {
      recentCust[rcfindIndex] = {
        ...recentCust[rcfindIndex],
        selected: event.target.checked
      }
      setRecentCustomers(recentCust)
    }
    setCustomersList(customerList)
    let selected = customerList.filter(c => c.selected)
    setDisableContinue(selected.length ? false : true)
  }

  const handleSelectAll = () => {
    let customerList: any = [...customersList]
    let notSelected: any = customerList.filter((ns: any) => !ns.selected)
    notSelected = notSelected.map((n: any) => {
      return { ...n, selected: true }
    })
    customerList = customerList.map((c: any) => {
      return { ...c, selected: true }
    })
    let recentCust = [...recentCustomers]
    recentCust = recentCust.map((c: any) => {
      return { ...c, selected: true }
    })
    setCustomersToPayload(notSelected)
    setRecentCustomers(recentCust)
    setCustomersList(customerList)
    setDisableContinue(false)
  }
  const handleClearAll = () => {
    let customerList: any = [...customersList]
    let selectedList: any = customerList.filter((c: any) => c.selected)
    selectedList = selectedList.map((s: any) => {
      return { ...s, selected: false }
    })
    customerList = customerList.map((c: any) => {
      return { ...c, selected: false }
    })
    let recentCust = [...recentCustomers]
    recentCust = recentCust.map((c: any) => {
      return { ...c, selected: false }
    })
    setCustomersToPayload(selectedList)
    setRecentCustomers(recentCust)
    setCustomersList(customerList)
    setDisableContinue(true)
  }

  const renderRecentCustomers = () => {
    //istanbul ignore next
    if (!recentCustomers.length) return null
    return recentCustomers.map((rc: any, i) => {
      return (
        <FormControlLabel
          key={`${rc.name - i}`}
          className="check-logo"
          control={
            <Checkbox
              name="lgpd_agreement"
              checkedIcon={<CheckCircleIcon />}
              checked={rc.selected}
              onChange={e => handleTileClick(e, rc)}
            />
          }
          label={
            <p>
              {rc.img ? (
                <img src={rc.img} alt="logo" />
              ) : (
                <ImageIcon style={{ minHeight: "60px" }} />
              )}
              <span>{rc.name}</span>
            </p>
          }
        />
      )
    })
  }
  const renderAllCustomers = () => {
    if (loading) return Loader()
    else {
      if (!customersList.length)
        return <span>{getTranslations(t, Translates.No_customers_Found)}</span>
      return customersList.map((c: any) => {
        return (
          <FormControlLabel
            key={`cust-${c.name}`}
            className="check-logo"
            control={
              <Checkbox
                name="lgpd_agreement"
                checked={c.selected}
                checkedIcon={<CheckCircleIcon />}
                onChange={e => handleTileClick(e, c)}
              />
            }
            label={
              <p>
                {c.img ? (
                  <img src={c.img} alt={`${c.name}_logo`} />
                ) : (
                  <ImageIcon style={{ minHeight: "60px" }} />
                )}
                <span>{c.name}</span>
              </p>
            }
          />
        )
      })
    }
  }
  return (
    <Grid container>
      <MiniHeader
        headerTitle={getTranslations(t, Translates.SELECT_CUSTOMER)}
        showCustName={false}
      />
      <Grid container className="inner-view">
        <Grid item xs={12} className="main-container">
          <Grid container>
            <Grid item xs={12}>
              <h4 className="inner-view__title">
                {getTranslations(t, Translates.Select_Customer)}{" "}
              </h4>
            </Grid>
            <Grid item xs={12} className="text-right toolbar-actions mb-2">
              <Button
                variant="contained"
                className="primary-btn mx-2"
                data-testid="SelectAll"
                onClick={handleSelectAll}
              >
                <CheckIcon className="mr-1" />
                {getTranslations(t, Translates.Select_All)}
              </Button>
              <Button
                variant="contained"
                className="secondary-btn"
                data-testid="Clear"
                onClick={handleClearAll}
              >
                <CloseIcon className="mr-1" />
                {getTranslations(t, Translates.Clear_All)}
              </Button>
            </Grid>
            <Grid item xs={12}>
              <div className="gray-bg pa-5 customers-dropdown">
                {customersList && customersList.length >= 14 ? (
                  <>
                    {recentCustomers.length ? (
                      <h6 className="mb-4">
                        {getTranslations(t, Translates.Recent_Customers)}
                      </h6>
                    ) : //istanbul ignore next
                    null}
                    {renderRecentCustomers()}
                    <Divider className="my-5 dashed" />
                  </>
                ) : null}
                <h6 className="mb-4">
                  {getTranslations(t, Translates.All_Customers)}
                </h6>
                {renderAllCustomers()}
              </div>
            </Grid>
            <Grid item xs={12} className="text-right mt-4">
              <Button
                variant="text"
                className="primary-btn cancel-btn mr-5"
                data-testid="Cancel"
                onClick={handleCancel}
              >
                {getTranslations(t, Translates.Cancel)}
              </Button>
              <Button
                variant="contained"
                className="primary-btn"
                onClick={handleContinue}
                data-testid="Contiue"
                disabled={disableContinue || loading || saving}
              >
                {getTranslations(t, Translates.Continue)}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default SelectCustomer
