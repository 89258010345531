import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { ScheduleService } from "../../../src/service/schedule.service"
import { ConfigService } from "../../../src/service/config.service"

export interface ScheduleState {
  status: string
  scheduleFilters: any
  scheduleFilterForm: any
  viewSchedule: any
  deleteSchedule: any
  validScheduleColumnList: string[]
  csvScheduleColumnList: string[]
  scheduleMappingData: any
  scheduleFileData: any
  mappingResponseData: any
  mappingPayloadData: any
  mappingProgress: any
  bulkScheduleFilePath: string
  scheduleSort: any
  scheduleColumn: any
}

const initialState: ScheduleState = {
  status: "",
  scheduleFilters: {
    region1: [],
    region2: [],
    region3: [],
    region4: [],
    country: [],
    state: [],
    currentDate: "",
    eventStatus: ["In Progress", "Not Started", "Closed", "Locked"]
  },
  scheduleFilterForm: {
    region1: [],
    region2: [],
    region3: [],
    region4: [],
    country: [],
    state: [],
    currentDate: "",
    eventStatus: ["In Progress", "Not Started", "Closed", "Locked"]
  },
  viewSchedule: {},
  deleteSchedule: {},
  validScheduleColumnList: [
    "Site ID",
    "Scheduled Date",
    "Scheduled Time",
    "Configuration Name",
    "Number Of Devices",
    "Notes"
  ],
  csvScheduleColumnList: [],
  scheduleMappingData: {},
  scheduleFileData: [""],
  mappingResponseData: {},
  mappingPayloadData: {},
  mappingProgress: 0,
  bulkScheduleFilePath: "",
  scheduleSort: "",
  scheduleColumn: ""
}
const scheduleService = new ScheduleService()
const configService = new ConfigService()
export const getViewSchedule = createAsyncThunk(
  "scheduleList/getViewSchedule",
  async (params: any) => {
    let customerId = params.payload
    return scheduleService.getviewschedule(customerId)
  }
)

export const deleteSchedule = createAsyncThunk(
  "scheduleList/deleteSchedule",
  async (params: any) => {
    let customerId = params.payload
    return scheduleService.deleteschedule(customerId)
  }
)

export const scheduleSlice = createSlice({
  name: "scheduleList",
  initialState,
  reducers: {
    setScheduleFilters: (state, _action: PayloadAction<any>) => {
      state.scheduleFilters = { ...state.scheduleFilters, ..._action.payload }
    },
    setScheduleFilterForm: (state, _action: PayloadAction<any>) => {
      state.scheduleFilterForm = { ..._action.payload }
    },
    setCSVScheduleColumnList: (state, _action: PayloadAction<any>) => {
      state.csvScheduleColumnList = [..._action.payload]
    },
    setScheduleMappingData: (state, _action: PayloadAction<any>) => {
      state.scheduleMappingData = { ..._action.payload }
    },
    setScheduleFileData: (state, _action: PayloadAction<any>) => {
      state.scheduleFileData = _action.payload
    },
    setBulkScheduleFile: (state, _action: PayloadAction<any>) => {
      state.bulkScheduleFilePath = _action.payload
    },
    setScheduleReset: () => initialState,
    setMappingResponseData: (state, _action: PayloadAction<any>) => {
      state.mappingResponseData = _action.payload
    },
    setMappingProgress: (state, _action: PayloadAction<any>) => {
      state.mappingProgress = _action.payload
    },
    setMappingPayloadData: (state, _action: PayloadAction<any>) => {
      state.mappingPayloadData = _action.payload
    },
    setScheduleSort: (state, _action: PayloadAction<any>) => {
      state.scheduleSort = _action.payload
    },
    setScheduleColumn: (state, _action: PayloadAction<any>) => {
      state.scheduleColumn = _action.payload
    }
  },
  extraReducers: builder => {
    builder.addCase(getViewSchedule.pending, state => {
      state.status = "loading"
    })
    //istanbul ignore next
    builder.addCase(getViewSchedule.fulfilled, (state, { payload }) => {
      state.status = "success"
      state.viewSchedule = payload.data
    })
    builder.addCase(getViewSchedule.rejected, state => {
      state.status = "failed"
    })

    builder.addCase(deleteSchedule.pending, state => {
      state.status = "loading"
    })
    //istanbul ignore next
    builder.addCase(deleteSchedule.fulfilled, (state, { payload }) => {
      state.status = "success"
      state.deleteSchedule = payload.data
    })
    builder.addCase(deleteSchedule.rejected, state => {
      state.status = "failed"
    })
  }
})
export const {
  setScheduleFilters,
  setScheduleFilterForm,
  setCSVScheduleColumnList,
  setScheduleMappingData,
  setScheduleFileData,
  setBulkScheduleFile,
  setScheduleReset,
  setMappingResponseData,
  setMappingPayloadData,
  setMappingProgress,
  setScheduleSort,
  setScheduleColumn
} = scheduleSlice.actions

export default scheduleSlice.reducer
