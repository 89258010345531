//BEGIN-NOSCAN
import { encrypt } from "src/utils/helper"
import ApiService from "src/utils/useAxios"
const api = new ApiService()
// istanbul ignore next
export class UploadFileService {
  getUploadFilesViewStatus(customerId) {
    const endPoint = `/api/UploadFiles/GetUploadFilesViewStatus?customerId=${customerId}`
    return api.findAll(endPoint)
  }

  getUploadFiles(customerId: string) {
    const endPoint = `/api/UploadFiles/GetUploadFiles?customerId=${customerId}`
    return api.findAll(endPoint)
  }

  getValidationDepartmentDetails(
    customerId: string,
    validationControlId: string
  ) {
    const endPoint = `/api/UploadFiles/getValidationDepartmentDetails?customerId=${customerId}&validationControlId=${validationControlId}`
    return api.findAll(endPoint)
  }

  deleteValidationDepartment(
    customerId: string,
    validationControlId: string,
    validationDepartmentId: string,
    updatedBy: string
  ) {
    const endPoint = `/api/UploadFiles/deleteValidationDepartment?customerId=${customerId}&validationControlId=${validationControlId}&validationDepartmentId=${validationDepartmentId}&updatedBy=${updatedBy}`
    return api.delete(endPoint, {})
  }
  deleteDepartmentData(
    customerId: string,
    validationControlId: string,
    validationDepartmentId: string
  ) {
    const endPoint = `api/UploadFiles/deleteValidationDepartment?customerId=${customerId}&validationControlId=${validationControlId}&validationDepartmentId=${validationDepartmentId}`
    return api.delete(endPoint, {})
  }
  deleteVarianceControlData(
    customerId: string,
    validationControlId: string,
    varianceControlId: string
  ) {
    const endPoint = `api/UploadFiles/deleteVarianceControl?validationControlId=${validationControlId}&varianceControlId=${varianceControlId}&customerId=${customerId}`
    return api.delete(endPoint, {})
  }

  getUploadSingleFileDetails(idConfig, fileType) {
    const endPoint = `/api/UploadFiles/GetUploadFileDetailsByFileType?configId=${idConfig}&idFileType=${fileType}`
    return api.findAll(endPoint)
  }
  updateData(payload: any) {
    const endPoint = "api/UploadFiles/UpsertUploadFileDetails"
    return api
      .post(endPoint, payload)
      .then((res: any) => {
        return res
      })
      .catch((err: any) => {
        return err
      })
  }
  AddBulkUploadFile(payload: any, config: any) {
    let params = { ...payload }
    let fileData = params.fileData ? encrypt(params.fileData) : ""
    params.fileData = fileData
    const endPoint = "/api/UploadFiles/AddAuxFileDataBulk"
    return api.saveWithParams(endPoint, params, config)
  }
  downloadAuxFile(idConfig: any, idFileType: any) {
    const endPoint = `/api/UploadFiles/DownloadAuxFile?configId=${idConfig}&idFileType=${idFileType}`
    return api.post(endPoint, "")
  }
}
//END-NOSCAN
