import { useEffect, useState, useRef } from "react"
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Tooltip,
  IconButton,
  Alert,
  AlertTitle
} from "@mui/material"
import { BsEyeSlashFill, BsEyeFill } from "react-icons/bs"
import BreadCrumb from "../../components/BreadCrumb/BreadCrumb"
import LocalPrintshopOutlinedIcon from "@mui/icons-material/LocalPrintshopOutlined"
import ReplyIcon from "@mui/icons-material/Reply"
import MainLayout from "../../../src/layouts/MainLayout"
import QRCode from "react-qr-code"
import { ScheduleService } from "../../../src/service/schedule.service"
import multiCustomerLogo from "../../../src/assets/images/MultiCustomer.png"
import {
  createImageFile,
  decrypt,
  decryptUrlParams,
  getTranslations
} from "../../../src/utils/helper"
import { useLocation } from "react-router-dom"
import { useReactToPrint } from "react-to-print"
import moment from "moment"
import { useSelector } from "react-redux"
import { RootState } from "src/redux/store"
import { useTranslation } from "react-i18next"
import { Translates } from "src/i18n/i18n"
import ConfirmDialog from "src/components/ConfirmDialog/ConfirmDialog"
import { Loader } from "src/utils/reusables/reusable"
import TextFieldChips from "src/utils/reusables/TextFieldChips"
import CheckIcon from "@mui/icons-material/Check"
import {
  SHARE_EVENTS,
  languageCodeMap,
  scheduleEDSecureFields
} from "src/utils/constants"
import LockOpenIcon from "@mui/icons-material/LockOpen"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import i18next from "i18next"

const ScheduleEventDetails = () => {
  const [qrValue, setQrValue] = useState("")
  const { t } = useTranslation<any>()
  const authSliceData = useSelector((state: RootState) => state.auth)
  const [eventDate, setEventDate] = useState("")
  const [eventTime, setEventTime] = useState("")
  const [storeNumber, setStoreNumber] = useState("")
  const [customerPIN, setCustomerPIN] = useState("")
  const [reservation, setReservation] = useState("")
  const [supervisorPassword, setSupervisorPassword] = useState("")
  const [noOfDevices, setNoOfDevices] = useState("")
  const [eventIdentifier, setEventIdentifier] = useState("")
  const [auditorPIN, setAuditorPIN] = useState("")
  const [configName, setConfigName] = useState("")
  const [configVersion, setConfigVersion] = useState("")
  const [logo, setLogo] = useState("")
  const [eventId, setEventId] = useState("")
  const [isShare, setIsShare] = useState(false)
  const [isConfirmOpen, setIsConfirmOpen] = useState(false)
  const [secureFieldsIcons, setSecureFieldsIcons] = useState({
    [scheduleEDSecureFields.eventPassword]: false,
    [scheduleEDSecureFields.authorizationNumber]: false,
    [scheduleEDSecureFields.countPIN]: false,
    [scheduleEDSecureFields.auditPIN]: false
  })
  const [loading, setLoading] = useState(false)
  const scheduleService = new ScheduleService()
  const customerList = useSelector(
    (state: RootState) => state.settings.customers
  )
  const selectedCustomerList = customerList.filter((c: any) => c.selected)
  const location: any = useLocation()
  let locationState: any = { ...location.state }
  let eventDetails = locationState?.eventDetails
  const componentRef = useRef(null)
  const qrSVG = useRef(null)
  const [isValid, setIsValid] = useState(true)
  const [chips, setChips] = useState<string[]>([])
  const [isChkChecked, setIsChkChecked] = useState(false)
  const [alertVisible, setAlertVisible] = useState(false)
  const [eventLockedFlag, setEventLockedFlag] = useState(false)

  interface IDetails {
    isChecked: boolean
    email: any
  }
  const [form, setForm] = useState<IDetails>({
    isChecked: false,
    email: []
  } as IDetails)

  useEffect(() => {
    setForm(prevForm => ({
      ...prevForm,
      email: chips
    }))
  }, [chips])
  useEffect(() => {
    setChips([])
    setForm({ ...form, isChecked: false })
  }, [isConfirmOpen])

  const onCheckboxChange = e => {
    const isChecked = e?.target?.checked
    setForm(prevForm => ({
      ...prevForm,
      isChecked: isChecked
    }))

    setIsChkChecked(isChecked)
    setChips([...form?.email])
  }

  useEffect(() => {
    let customer: any = selectedCustomerList.find(
      (c: any) => c.idCustomer == eventDetails?.idCustomer
    )
    if (customer?.companyLogo) {
      setLogo(customer.companyLogo)
    } else {
      setLogo(multiCustomerLogo)
    }
  }, [selectedCustomerList])

  useEffect(() => {
    if (authSliceData?.keys?.length) {
      let decryptedId: any
      let pathname: string | string[] = window.location.pathname
      pathname = pathname.split("/")
      let len = pathname.length
      let id: any = pathname[len - 1] || ""
      decryptedId = decryptUrlParams(id)
      setQrValue(eventDetails?.eventGuid)
      setEventId(decryptedId)

      const lang = i18next.language
      moment.locale(languageCodeMap[lang])


      if (decryptedId && eventDetails) {
        const eventdate = eventDetails.scheduledDateTime
          ? moment(eventDetails.scheduledDateTime)
              .format("DD-MMM-YYYY")
              .toUpperCase()
          : ""
        const eventTime = eventDetails.scheduledDateTime
          ? moment(eventDetails.scheduledDateTime).format("hh:mm A")
          : ""
        setEventDate(eventdate)
        setEventTime(eventTime)
        setStoreNumber(eventDetails.siteId ? eventDetails.siteId : "")
        setConfigName(eventDetails.configName ? eventDetails.configName : "")

        setConfigVersion(
          eventDetails.configVersion ? eventDetails.configVersion : ""
        )
        setReservation(
          eventDetails.reservationName ? eventDetails.reservationName : ""
        )
        setNoOfDevices(
          eventDetails.numberofDevices ? eventDetails.numberofDevices : ""
        )
        setEventLockedFlag(eventDetails?.eventLockedFlag)
        getEventDetails(decryptedId)
      }
    }
  }, [authSliceData?.keys, t])

  const getEventDetails = decryptedId => {
    if (decryptedId) {
      setLoading(true)
      scheduleService
        .getEventPasswords(decryptedId)
        .then(async (resp: any) => {
          const decyptedResp = JSON.parse(decrypt(resp.data))

          setCustomerPIN(decyptedResp?.CounterPassword)
          setAuditorPIN(decyptedResp?.AuditorPassword)
          setEventIdentifier(decyptedResp?.AuthorizationNumber)
          setSupervisorPassword(decyptedResp?.SupervisorPassword)
          setLoading(false)
        })
        .catch(err => {
          console.log("error while fetching schedule details ", err)
          setLoading(false)
        })
    }
  }
  // Unlock Event API Call
  const handleUnlockClick = () => {
    setLoading(true)
    let payload = { eventId, eventIdentifier }
    scheduleService
      .unlockEvent(payload)
      .then(() => {
        setLoading(false)
        setAlertVisible(true)
        setTimeout(() => {
          setAlertVisible(false)
        }, 2000)
        setEventLockedFlag(false)
      })
      .catch(err => {
        setLoading(false)
        console.log("err while unlocking the event ", err)
      })
  }

  const handleEmailClick = () => {
    setIsShare(true)
    setIsConfirmOpen(true)
  }
  const handlePrintClick = useReactToPrint({
    content: () => componentRef.current
  })

  const getBreadcrumbConfig = () => {
    let currentPath: string = `schedule/eventDetails`
    let variables: any = {}
    variables["schedule"] = `${getTranslations(t, Translates.Schedule)}`
    variables[`eventDetails`] = `${getTranslations(
      t,
      Translates.Share_Schedule_Details
    )}`
    let bConfig: any = {
      currentPath,
      mapObj: variables
    }
    return bConfig
  }
  const setConfirmContent = () => {
    return (
      <>
        <TextFieldChips
          getChips={setChips}
          form={{ chips: chips }}
          isValid={isValid}
          setIsValid={setIsValid}
        ></TextFieldChips>
        <FormControlLabel
          className="custom-check__cont mb-3 mt-1"
          control={
            <Checkbox
              data-testid="checkbox-testid"
              checked={form?.isChecked}
              className="custom-check"
              onChange={e => onCheckboxChange(e)}
              checkedIcon={<CheckIcon />}
            />
          }
          label={getTranslations(t, Translates.Send_me_a_Copy)}
        />
      </>
    )
  }

  const callbackConfirmation = (res: boolean) => {
    if (res) {
      if (isChkChecked) {
        const userEmail = localStorage?.getItem("username") || ""
        sendEmailWithQRCode([...chips, userEmail])
      } else {
        sendEmailWithQRCode(chips)
      }
    } else {
      setIsConfirmOpen(false)
    }
    setIsChkChecked(false)
  }
  const createFormData = (
    fileQRCode: File,
    emailList: string[],
    eventDateTime: string
  ): FormData => {
    const formData = new FormData()
    formData.append("EventId", eventId)
    emailList.forEach(value => formData.append("EmailIds", value))
    formData.append("EventDate", eventDateTime)
    formData.append("QrCode", fileQRCode)
    return formData
  }

  const getSvgBlob = (svgElement: SVGSVGElement): Promise<Blob> => {
    return new Promise((resolve, reject) => {
      try {
        const svgString = new XMLSerializer().serializeToString(svgElement)
        const svgBlob = new Blob([svgString], { type: "image/svg+xml" })
        resolve(svgBlob)
      } catch (error) {
        reject(error)
      }
    })
  }

  const sendEmailWithQRCode = async (emailList: string[]) => {
    // To change lang Eng in payload for date
    moment.locale("us_en")
    const eventdate = eventDetails.scheduledDateTime
      ? moment(eventDetails.scheduledDateTime)
          .format("DD-MMM-YYYY")
          .toUpperCase()
      : ""

    const _eventDateTime = `${eventdate} ${eventTime}`
    const qrCodeSvg = qrSVG?.current as unknown as SVGSVGElement | undefined

    if (!qrCodeSvg) {
      return
    }

    try {
      const svgBlob = await getSvgBlob(qrCodeSvg)
      const _fileQRCode = await createImageFile(svgBlob)
      const formData = createFormData(_fileQRCode, emailList, _eventDateTime)

      try {
        await scheduleService.sendEmail(formData, {
          headers: { [SHARE_EVENTS.Content_Type]: SHARE_EVENTS.Content_data }
        })
        setIsConfirmOpen(false)
      } catch (err: any) {
        setLoading(false)
      } finally {
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
    }
  }

  const LABELS_COL1 = [
    {
      label: Translates.Date,
      value: eventDate,
      class_name: "blue-text font-18",
      sortPrint: 1
    },
    {
      label: Translates.Time,
      value: eventTime,
      sortPrint: 2
    },
    {
      label: Translates.Count_PIN,
      value: customerPIN,
      sortPrint: 9,
      secureName: scheduleEDSecureFields.countPIN
    }
  ]

  const LABEL_COL2 = [
    {
      label: Translates.Site_ID,
      value: storeNumber,
      sortPrint: 3
    },
    {
      label: Translates.Config_Name,
      value: `${configName} (${configVersion})`,
      sortPrint: 6
    },
    {
      label: Translates.Audit_PIN,
      value: auditorPIN,
      sortPrint: 10,
      secureName: scheduleEDSecureFields.auditPIN
    }
  ]
  const LABEL_COL3 = [
    {
      label: Translates.Reservation,
      value: reservation,
      sortPrint: 4
    },
    {
      label: Translates.Authorization_Number,
      value: eventIdentifier,
      sortPrint: 7,
      secureName: scheduleEDSecureFields.authorizationNumber
    }
  ]
  const LABEL_COL4 = [
    {
      label: Translates.Number_of_Devices,
      value: noOfDevices,
      sortPrint: 5
    },

    {
      label: Translates.Event_Password,
      value: supervisorPassword,
      sortPrint: 8,
      secureName: scheduleEDSecureFields.eventPassword
    }
  ]

  const EVENT_DETAILS_COL = [
    ...LABELS_COL1,
    ...LABEL_COL2,
    ...LABEL_COL3,
    ...LABEL_COL4
  ].sort((a, b) => a.sortPrint - b.sortPrint)

  // Calculate the midpoint of the array
  const midpoint = Math.ceil(EVENT_DETAILS_COL.length / 2)

  const EVENT_DETAILS_COL1 = EVENT_DETAILS_COL.slice(0, midpoint)
  const EVENT_DETAILS_COL2 = EVENT_DETAILS_COL.slice(midpoint)

  const renderMultipleItems = (list, isFieldMasked = false) => {
    return list.map(item => {
      return renderSingleSpanItem(item, isFieldMasked)
    })
  }

  const secureFieldIconClickUpdate = item => {
    setSecureFieldsIcons(oldVal => ({
      ...oldVal,
      [item.secureName]: !oldVal[item.secureName]
    }))
  }
  const renderSingleSpanItem = (item, isFieldMasked = false) => {
    let value = `${item.value}`
    let showPassword = true

    if (isFieldMasked) {
      if (item.secureName === scheduleEDSecureFields.authorizationNumber) {
        showPassword =
          secureFieldsIcons[scheduleEDSecureFields.authorizationNumber]
      } else if (item.secureName === scheduleEDSecureFields.auditPIN) {
        showPassword = secureFieldsIcons[scheduleEDSecureFields.auditPIN]
      } else if (item.secureName === scheduleEDSecureFields.countPIN) {
        showPassword = secureFieldsIcons[scheduleEDSecureFields.countPIN]
      } else if (item.secureName === scheduleEDSecureFields.eventPassword) {
        showPassword = secureFieldsIcons[scheduleEDSecureFields.eventPassword]
      }

      if (!showPassword) {
        value = "*".repeat(value.length)
      }
    }

    return (
      <span className={item.class_name} key={item.value}>
        <strong>{getTranslations(t, item.label)}:</strong>{" "}
        {isFieldMasked && item.secureName ? (
          <div className="d-flex mt-1">
            <span>{value}</span>
            <IconButton
              onClick={() => secureFieldIconClickUpdate(item)}
              className="ml-1"
              style={{ marginTop: "-12px" }}
            >
              {showPassword ? (
                <BsEyeFill size={22} data-testid="eyeIcon" />
              ) : (
                <BsEyeSlashFill size={22} data-testid="eyeIconSlash" />
              )}
            </IconButton>
          </div>
        ) : (
          value
        )}
      </span>
    )
  }

  return (
    <MainLayout>
      <div className="main-toolbar">
        <div className="main-container">
          <Grid container>
            <Grid item sm={5}>
              <div className="custom-breadcrumb">
                <BreadCrumb
                  customPath={"Schedule"}
                  breadCrumbInfo={getBreadcrumbConfig()}
                  renderedPage={""}
                />
              </div>
            </Grid>
            <Grid item sm={7} className="text-right">
              <Tooltip arrow title={`${getTranslations(t, Translates.Unlock)}`}>
                <Button
                  variant="text"
                  className="icon-btn mx-2"
                  data-testid="unlockEvent"
                  disabled={!eventLockedFlag}
                >
                  <LockOpenIcon onClick={handleUnlockClick} />
                </Button>
              </Tooltip>
              <Tooltip arrow title={`${getTranslations(t, Translates.Share)}`}>
                <Button
                  variant="text"
                  className="icon-btn mx-2"
                  data-testid="shareIcon"
                >
                  <ReplyIcon
                    style={{ transform: "rotateY(180deg)" }}
                    data-testid="replyIcon"
                    onClick={handleEmailClick}
                  />
                </Button>
              </Tooltip>
              <Tooltip arrow title={`${getTranslations(t, Translates.Print)}`}>
                <Button
                  variant="text"
                  className="icon-btn mx-2"
                  data-testid="printIcon"
                >
                  <LocalPrintshopOutlinedIcon
                    data-testid="printIcon1"
                    onClick={handlePrintClick}
                  />
                </Button>
              </Tooltip>
            </Grid>
          </Grid>
        </div>
      </div>{" "}
      {loading ? (
        Loader()
      ) : (
        <div className="main-container">
          <div className="schedule-container">
            <Grid container className="schedule-grid">
              <Grid item className="schedule-grid__col">
                <img
                  src={logo}
                  alt={"Portal Logo"}
                  className="schedule-grid__col--logo"
                />
              </Grid>
              <Grid item className="schedule-grid__col">
                {renderMultipleItems(LABELS_COL1, true)}
              </Grid>
              <Grid item className="schedule-grid__col">
                {renderMultipleItems(LABEL_COL2, true)}
              </Grid>
              <Grid item className="schedule-grid__col">
                {renderMultipleItems(LABEL_COL3, true)}
              </Grid>
              <Grid item className="schedule-grid__col">
                {renderMultipleItems(LABEL_COL4, true)}
              </Grid>
              <Grid item className="schedule-grid__col pr-0">
                <div className="qr-code">
                  {qrValue && <QRCode ref={qrSVG} value={qrValue} />}
                </div>
              </Grid>
            </Grid>
            <div className="d-none">
              <Grid
                container
                className="schedule-grid print-row"
                ref={componentRef}
              >
                <Grid item className="schedule-grid__col">
                  <img
                    src={logo}
                    alt={"Portal Logo"}
                    className="schedule-grid__col--logo"
                  />
                </Grid>
                <Grid item className="schedule-grid__col">
                  {renderMultipleItems(EVENT_DETAILS_COL1)}
                </Grid>
                <Grid item className="schedule-grid__col">
                  {renderMultipleItems(EVENT_DETAILS_COL2)}
                </Grid>
                <Grid item className="schedule-grid__col pr-0">
                  <div className="qr-code">
                    {qrValue && <QRCode value={qrValue} />}
                  </div>
                </Grid>
              </Grid>
            </div>

            {isShare ? (
              <ConfirmDialog
                open={isConfirmOpen}
                callBack={callbackConfirmation}
                title={getTranslations(t, Translates.SHARE_EVENT)}
                content={setConfirmContent}
                custref={{}}
                confirmButtonText={getTranslations(t, Translates.SEND)}
                cancelButtonText={getTranslations(t, Translates.CANCEL)}
                isConfirmButtonShow={!chips.length || !isValid ? false : true}
              ></ConfirmDialog>
            ) : null}
          </div>
        </div>
      )}
      {alertVisible ? (
        <Alert
          severity="success"
          icon={<CheckCircleIcon />}
          className="custom-alert top"
        >
          <AlertTitle className="custom-alert__title f-600">
            {getTranslations(t, Translates.Success)}
          </AlertTitle>

          <p>{getTranslations(t, Translates.Event_unlocked_successfully)}</p>
        </Alert>
      ) : null}
    </MainLayout>
  )
}

export default ScheduleEventDetails
