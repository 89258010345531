import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  Button,
  TextField,
  ListItemIcon
} from "@mui/material"
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import moment from "moment"
import { getTranslations, sortData } from "../helper"

const FilterComponent = ({
  t,
  Translates,
  storeData,
  multipleCustomersAssigned,
  handleChange,
  filteredRegion1Values,
  filteredRegion2Values,
  filteredRegion3Values,
  filteredRegion4Values,
  region1,
  region2,
  region3,
  region4,
  MenuProps,
  countryName,
  handleChangeCountry,
  uniqCountries,
  stateName,
  handleChangeState,
  uniqStates,
  date,
  onDateChange,
  handleKeyDown,
  statusName,
  handleChangeStatus,
  isAllSelected,
  sortedStatus,
  handleResetAllClick,
  applyCancelClick,
  applyFilterClick,
  uniqSiteIds = undefined as any,
  siteIdSelects = [] as any,
  handleChangeSiteId = _e => {}
}) => {
  return (
    <div className="filter-panel">
      <Grid container className="main-container">
        <Grid item xs={12}>
          <div className="gray-bg pa-4">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <h4 className="mb-2">
                  {getTranslations(t, Translates.Filters)}
                </h4>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  {storeData?.[0]?.regionalGroup1Label &&
                  !multipleCustomersAssigned ? (
                    <Grid item xs={12} sm={3}>
                      <label className="f-600 text-uppercase mb-2">
                        {storeData[0].regionalGroup1Label}
                      </label>
                      <div className="text-box">
                        <FormControl
                          variant="filled"
                          className="custom-form-control select light"
                        >
                          <InputLabel
                            id="demo-multiple-checkbox-label"
                            variant="filled"
                          >
                            {storeData[0].regionalGroup1Label}
                          </InputLabel>
                          <Select
                            id="demo-multiple-checkbox"
                            multiple
                            value={region1}
                            onChange={e => handleChange(e, 1)}
                            disabled={multipleCustomersAssigned}
                            renderValue={selected => selected.join(", ")}
                            MenuProps={MenuProps}
                          >
                            {sortData(filteredRegion1Values)?.map(name => (
                              <MenuItem key={name} value={name}>
                                <Checkbox
                                  checked={region1.indexOf(name) > -1}
                                />
                                <ListItemText primary={name} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                    </Grid>
                  ) : null}
                  {storeData?.[0]?.regionalGroup2Label &&
                  !multipleCustomersAssigned ? (
                    <Grid item xs={12} sm={3}>
                      <label className="f-600 text-uppercase mb-2">
                        {storeData[0].regionalGroup2Label}
                      </label>
                      <div className="text-box">
                        <FormControl
                          variant="filled"
                          className="custom-form-control select light"
                        >
                          <InputLabel
                            id="demo-multiple-checkbox-label"
                            variant="filled"
                          >
                            {storeData[0].regionalGroup2Label}
                          </InputLabel>
                          <Select
                            id="demo-multiple-checkbox"
                            multiple
                            value={region2}
                            onChange={e => handleChange(e, 2)}
                            disabled={multipleCustomersAssigned}
                            renderValue={selected => selected.join(", ")}
                            MenuProps={MenuProps}
                          >
                            {sortData(filteredRegion2Values)?.map(name => (
                              <MenuItem key={name} value={name}>
                                <Checkbox
                                  checked={region2.indexOf(name) > -1}
                                />
                                <ListItemText primary={name} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                    </Grid>
                  ) : null}
                  {storeData?.[0]?.regionalGroup3Label &&
                  !multipleCustomersAssigned ? (
                    <Grid item xs={12} sm={3}>
                      <label className="f-600 text-uppercase mb-2">
                        {storeData[0].regionalGroup3Label}
                      </label>
                      <div className="text-box">
                        <FormControl
                          variant="filled"
                          className="custom-form-control select light"
                        >
                          <InputLabel
                            id="demo-multiple-checkbox-label"
                            variant="filled"
                          >
                            {storeData[0].regionalGroup3Label}
                          </InputLabel>
                          <Select
                            id="demo-multiple-checkbox"
                            multiple
                            value={region3}
                            onChange={e => handleChange(e, 3)}
                            disabled={multipleCustomersAssigned}
                            renderValue={selected => selected.join(", ")}
                            MenuProps={MenuProps}
                          >
                            {sortData(filteredRegion3Values)?.map(name => (
                              <MenuItem key={name} value={name}>
                                <Checkbox
                                  checked={region3.indexOf(name) > -1}
                                />
                                <ListItemText primary={name} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                    </Grid>
                  ) : null}
                  {storeData?.[0]?.regionalGroup4Label &&
                  !multipleCustomersAssigned ? (
                    <Grid item xs={12} sm={3}>
                      <label className="f-600 text-uppercase mb-2">
                        {storeData[0].regionalGroup4Label}
                      </label>
                      <div className="text-box">
                        <FormControl
                          variant="filled"
                          className="custom-form-control select light"
                        >
                          <InputLabel
                            id="demo-multiple-checkbox-label"
                            variant="filled"
                          >
                            {storeData[0].regionalGroup4Label}
                          </InputLabel>
                          <Select
                            id="demo-multiple-checkbox"
                            multiple
                            value={region4}
                            onChange={e => handleChange(e, 4)}
                            disabled={multipleCustomersAssigned}
                            renderValue={selected => selected.join(", ")}
                            MenuProps={MenuProps}
                          >
                            {sortData(filteredRegion4Values)?.map(name => (
                              <MenuItem key={name} value={name}>
                                <Checkbox
                                  checked={region4.indexOf(name) > -1}
                                />
                                <ListItemText primary={name} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                    </Grid>
                  ) : null}
                </Grid>
              </Grid>
              {uniqSiteIds ? (
                <Grid item xs={12} sm={3}>
                  <label className="f-600 text-uppercase mb-2">
                    {getTranslations(t, Translates.Site_ID)}
                  </label>
                  <div className="text-box">
                    <FormControl
                      variant="filled"
                      className="custom-form-control select light"
                    >
                      <InputLabel
                        id="demo-multiple-checkbox-label"
                        variant="filled"
                      >
                        {getTranslations(t, Translates.Site_ID)}
                      </InputLabel>
                      <Select
                        id="demo-multiple-checkbox"
                        multiple
                        value={siteIdSelects}
                        onChange={handleChangeSiteId}
                        renderValue={selected => selected.join(", ")}
                        MenuProps={MenuProps}
                      >
                        {uniqSiteIds?.map(name => (
                          <MenuItem key={name} value={name}>
                            <Checkbox
                              checked={siteIdSelects.indexOf(name) > -1}
                            />
                            <ListItemText primary={name} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </Grid>
              ) : null}

              <Grid item xs={12} sm={3}>
                <label className="f-600 text-uppercase mb-2">
                  {getTranslations(t, Translates.Country)}
                </label>
                <div className="text-box">
                  <FormControl
                    variant="filled"
                    className="custom-form-control select light"
                  >
                    <InputLabel
                      id="demo-multiple-checkbox-label"
                      variant="filled"
                    >
                      {getTranslations(t, Translates.Country)}
                    </InputLabel>
                    <Select
                      id="demo-multiple-checkbox"
                      multiple
                      value={countryName}
                      onChange={handleChangeCountry}
                      renderValue={selected => selected.join(", ")}
                      MenuProps={MenuProps}
                    >
                      {sortData(uniqCountries)?.map(name => (
                        <MenuItem key={name} value={name}>
                          <Checkbox checked={countryName.indexOf(name) > -1} />
                          <ListItemText primary={name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </Grid>
              <Grid item xs={12} sm={3}>
                <label className="f-600 text-uppercase mb-2">
                  {getTranslations(t, Translates.State)}
                </label>
                <div className="text-box">
                  <FormControl
                    variant="filled"
                    className="custom-form-control select light"
                  >
                    <InputLabel
                      id="demo-multiple-checkbox-label"
                      variant="filled"
                    >
                      {getTranslations(t, Translates.State)}
                    </InputLabel>
                    <Select
                      id="demo-multiple-checkbox"
                      multiple
                      value={stateName}
                      onChange={handleChangeState}
                      renderValue={selected => selected.join(", ")}
                      MenuProps={MenuProps}
                    >
                      {sortData(uniqStates)?.map(name => (
                        <MenuItem key={name} value={name}>
                          <Checkbox checked={stateName.indexOf(name) > -1} />
                          <ListItemText primary={name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </Grid>

              <Grid item xs={12} sm={3}>
                <label className="f-600 text-uppercase mb-2">
                  {getTranslations(t, Translates.Scheduled_Date)}
                </label>
                <div className="text-box">
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <div className="text-box">
                      <DesktopDatePicker
                        label={"Select Date"}
                        inputFormat="dd-MMM-yyyy"
                        value={
                          date?.scheduledDateTime
                            ? moment(date.scheduledDateTime)
                            : null
                        }
                        onChange={value => {
                          onDateChange({
                            target: {
                              name: "scheduledDateTime",
                              value: value
                            }
                          })
                        }}
                        renderInput={params => (
                          <TextField
                            data-testid="selectDate"
                            {...params}
                            fullWidth
                            onKeyDown={handleKeyDown}
                            placeholder="dd-mmm-yyyy"
                            label={getTranslations(t, Translates.Select_Date)}
                            variant="filled"
                            error={false}
                            className="custom-form-control light date"
                          />
                        )}
                      />
                    </div>
                  </LocalizationProvider>
                </div>
              </Grid>
              <Grid item xs={12} sm={3}>
                <label className="f-600 text-uppercase mb-2">
                  {getTranslations(t, Translates.Status)}
                </label>
                <div className="text-box">
                  <FormControl
                    variant="filled"
                    className="custom-form-control select light"
                  >
                    <InputLabel
                      id="demo-multiple-checkbox-label"
                      variant="filled"
                    >
                      {getTranslations(t, Translates.Status)}
                    </InputLabel>
                    <Select
                      id="demo-multiple-checkbox"
                      multiple
                      value={statusName}
                      onChange={handleChangeStatus}
                      renderValue={selected => {
                        return isAllSelected
                          ? getTranslations(t, Translates.All)
                          : sortedStatus
                              .filter(item => selected.includes(item.value))
                              .map(item => item.label)
                              .join(", ")
                      }}
                      MenuProps={MenuProps}
                    >
                      <MenuItem value="All">
                        <ListItemIcon>
                          <Checkbox checked={isAllSelected} />
                        </ListItemIcon>
                        <ListItemText
                          primary={getTranslations(t, Translates.All)}
                        />
                      </MenuItem>

                      {sortedStatus.map(i => (
                        <MenuItem key={i.value} value={i.value}>
                          <Checkbox
                            checked={statusName.indexOf(i.value) > -1}
                          />
                          <ListItemText primary={i.label} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </Grid>

              <Grid item xs={12}>
                <Grid container className="inner-view__footer mt-6 mb-4">
                  <Grid item sm={4} className="text-left">
                    <Button
                      id="filterButton"
                      variant="text"
                      className="primary-btn mr-5 cancel-btn"
                      onClick={handleResetAllClick}
                    >
                      {getTranslations(t, Translates.RESET_TO_DEFAULT)}
                    </Button>
                  </Grid>
                  <Grid item sm={8}>
                    <Button
                      id="filterButton"
                      variant="text"
                      className="primary-btn mr-5 cancel-btn"
                      data-testid="cancelBtn"
                      onClick={applyCancelClick}
                    >
                      {getTranslations(t, Translates.Cancel)}
                    </Button>
                    <Button
                      id="filterButton"
                      variant="contained"
                      className="primary-btn"
                      onClick={applyFilterClick}
                    >
                      {getTranslations(t, Translates.Apply_Filter)}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

export default FilterComponent
